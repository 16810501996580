import React, { useEffect, useState } from 'react';
import {
  Button, Modal, Tooltip, Input, DatePicker, Popover, Tag,
} from 'antd';
import {
  EditOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import html2canvas from 'html2canvas';
import { parse, stringify } from 'query-string';
import { jsPDF } from 'jspdf';
import StarRatings from 'react-star-ratings';
import moment from 'moment';
import TagsBar from '../../../common/components/tagsBar';
import { createBookmarkResetAction, deleteBookmarkResetAction } from '../../../common/components/Bookmark/logic';
import DocumentList from '../DocumentList';
import CenterDetails from './CenterDetails';
import TrialsAtSite from './TrialsAtSite';
import { getDeepdiveValuesAction } from './logic';
import { getSiteQuesDataAction, updateSiteResetAction } from '../../components/AddCentersManually/logic';
import CentersOtherKeyPersonal from '../CentersOtherKeyPersonal';
import CenterFacilities from '../CenterFacilities';
import CenterPatientAccess from '../CenterPatientAccess';
import DashboardData from '../DashboardData';
import CenterSurvey from '../CenterSurvey';
import InvestigatorHierarchy from '../InvestigatorHierarchy';
import Loader from '../../../common/components/Loader';
import { markSiteValidInvalidAction } from '../../components/InvestigatorProfilePage/logic';
import { getStateDataResetAction } from '../GeneralInformation/logic';
import CenterNetwork from '../Network/CenterNetwork';
import { getCookie, isSponsor, setAnalytics } from '../../../utils';
import Comment from '../Comment';
import Bookmark from '../../../common/components/Bookmark';
import AddCentersManuallyAddNew from '../../components/AddCenterManuallyAddNew';
import InstitutionDetails from '../InstitutionDetails';
import { getInstituteDeepdiveListAction } from '../InstitutionDetails/logic';
import { updateParentResetAction, updateSiteAction, updateParentAction } from '../../components/AddCenterManuallyAddNew/logic';
import InstitutionMetrics from '../InstitutionMetrics';
import InstitutionAllianceDetails from '../InstitutionAllianceDetails';
import InstituteTrialsAtSite from '../InstituteTrialsAtSite';
import AddEditParentInstitute from '../../components/AddEditParentInstitute';
import ParentCenterHierarchy from '../ParentCenterHierarchy';
import { getStudiesDataAction } from '../InvestigatorStudies/logic';
import Template from '../Template';
import { getDatasetListDataAction } from '../SearchResults/logic';
import QualityMetricsForCenters from '../QualityMetricesForCenters';
import ParentCompliance from '../ParentCompliance';
import { getParentInstitutionHierarchyAction } from '../InvestigatorHierarchy/logic';

const DeepDives = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { TextArea } = Input;

  const history = useHistory();
  const deepDiveDataResponse = useSelector((state) => state.deepDive);
  const studiesData = useSelector((store) => store.studiesData);
  const siteQuesResponse = useSelector((store) => store.siteQuesResponse);
  const instituteDeepdiveList = useSelector((store) => store.instituteDeepdiveList);
  const datasetListData = useSelector((state) => state.datasetListData);
  const [deepDiveData, setDeepDiveData] = useState([])
  const [dateError, setDateError] = useState(false)
  const [typeOfData, setTypeOfData] = useState('All')
  const [studiesList, setStudiesList] = useState([]);
  const [exportLoading, setExportLoading] = useState(false)
  const [listData, setListData] = useState([]);
  const [exportCenterModal, setExportCenterModal] = useState(false)

  const [showAlias, setAlias] = useState(false)
  const [editAliasesFlag, setEditAliasesFlag] = useState(false);
  const [defaultValueOfAlias, setDefaultValueOfAlias] = useState('');
  const [inputValueForAlias, setInputValueForAlias] = useState('');

  const updateSiteResponse = useSelector((reduxState) => reduxState.updateSiteResponse || {})
  const updateParentResponse = useSelector((reduxState) => reduxState.updateParentResponse || {})

  useEffect(() => {
    dispatch(getSiteQuesDataAction())
    dispatch(getStudiesDataAction({
      id: parse(location.search).id,
      dataset: 'centers',
      from: 1,
      size: 10,
    }))
    dispatch(
      getDatasetListDataAction({
        center_type: 'primary',
        center_id: centerId,
        dataset: 'investigators',
        from: 1,
        size: 10,
      }),
    );
  }, [])

  useEffect(() => {
    if (updateSiteResponse.flag) {
      dispatch(getDeepdiveValuesAction({ id: centerId, dataset: 'centers' }));
      setAlias(false);
    }
  }, JSON.stringify(updateSiteResponse))

  useEffect(() => {
    if (updateParentResponse.flag) {
      dispatch(getInstituteDeepdiveListAction({ id: centerId }));
      setAlias(false);
      dispatch(
        getParentInstitutionHierarchyAction({
          id: parse(location.search).id,
          alliance_type: typeOfData,
          filters: JSON.stringify({
            date: dateFilter,
          }),
        }),
      );
    }
  }, [JSON.stringify(updateParentResponse)])

  useEffect(() => {
    if (studiesData.flag) {
      setStudiesList(studiesData.data.data);
    }
  }, [JSON.stringify(studiesData)])

  useEffect(() => {
    if (datasetListData.flag) {
      setListData(datasetListData.data.data);
    }
  }, [JSON.stringify(datasetListData)]);

  useEffect(() => {
    if (parse(location.search).currentTab === 'parent_centers' && instituteDeepdiveList.flag) {
      setDeepDiveData(instituteDeepdiveList?.data)
      setInputValueForAlias(instituteDeepdiveList?.data?.data?.pxl_aliases.join('\n'))
      setDefaultValueOfAlias(instituteDeepdiveList?.data?.data?.pxl_aliases)
    }
    if (parse(location.search).currentTab === 'centers' && deepDiveDataResponse.flag) {
      setDeepDiveData(deepDiveDataResponse)
      setInputValueForAlias(deepDiveDataResponse?.data?.pxl_aliases.join('\n'))
      setDefaultValueOfAlias(deepDiveDataResponse?.data?.pxl_aliases)
    }
  }, [JSON.stringify(instituteDeepdiveList), JSON.stringify(deepDiveDataResponse)])

  function addCategory(groupedData, item) {
    if (parse(location.search).currentTab === 'parent_centers') {
      if (!groupedData[item.parent_category]) {
        groupedData[item.parent_category] = {
          items: [],
          hasSubcategories: false,
        };
      }

      groupedData[item.parent_category].items.push(item);
    } else {
      if (!groupedData[item.child_category]) {
        groupedData[item.child_category] = {
          items: [],
          hasSubcategories: false,
        };
      }

      groupedData[item.child_category].items.push(item);
    }
  }

  function groupByCategory(data) {
    const groupedData = {};
    if (parse(location.search).currentTab === 'parent_centers') {
      if (data) {
        data.forEach((item) => {
          let condition = false
          if (parse(location.search).currentTab === 'centers') {
            condition = !groupedData[item.parent_category] && !(parse(location.search).currentTab === 'centers') && (!item?.applicable.includes('child') || !item?.applicable.includes('parent'));
          }
          if (item.parent_category === 'Other Key Personnel' || item.parent_category === 'General Information' || (condition)
            || (condition && parse(location.search).currentTab !== 'parent_centers')) {
            return {}
          }

          if (parse(location.search).currentTab === 'parent_centers' && item?.applicable.includes('parent')) {
            addCategory(groupedData, item)
          } else {
            return {}
          }

          if (item.parent_sub_category && item.parent_sub_category.length > 0 && item.parent_sub_category !== item.parent_category) {
            groupedData[item.parent_category].hasSubcategories = true;
          }
          return {}
        });
        return groupedData;
      }
    } else if (data) {
      data.forEach((item) => {
        let condition = false
        if (parse(location.search).currentTab === 'centers') {
          condition = !groupedData[item.child_category] && !(parse(location.search).currentTab === 'centers') && (!item?.applicable.includes('child') || !item?.applicable.includes('parent'));
        }
        if (item.child_category === 'Other Key Personnel' || (condition)
          || (condition && parse(location.search).currentTab !== 'parent_centers')) {
          return {}
        }

        if ((parse(location.search).currentTab === 'centers') && parse(location.search).currentTab !== 'parent_centers' && item?.applicable.includes('child')) {
          addCategory(groupedData, item)
        } else if ((parse(location.search).currentTab === 'centers') && parse(location.search).currentTab === 'parent_centers' && item?.applicable.includes('parent')) {
          addCategory(groupedData, item)
        } else {
          return {}
        }

        if (item.child_sub_category && item.child_sub_category.length > 0 && item.child_sub_category !== item.child_category) {
          groupedData[item.child_category].hasSubcategories = true;
        }
        return {}
      });
      return groupedData;
    }
    return {}
  }
  const getCategory = () => {
    const groupedData = groupByCategory(siteQuesResponse?.data?.data)
    if (groupedData) {
      const updatedData = { ...groupedData };
      delete updatedData.Quality;
      return Object.keys(updatedData);
    }
    return [];
  };

  let navTabs = [
    {
      name: 'Center Details',
      key: 'centerdetails',
      additionalClass: '',
      title: '',
    },
    {
      name: 'Center Hierarchy',
      key: 'centerhierarchy',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    },
    {
      name: 'Trials at Site',
      key: 'trialsatsite',
      additionalClass: '',
      title: '',
    },
    {
      name: 'Principal Investigators',
      key: 'principalinvestigators',
      additionalClass: '',
      title: '',
    },
    {
      name: 'Survey',
      key: 'survey',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    },
    {
      name: 'Networks',
      key: 'networks',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    },
    {
      name: 'Other Key Personnel',
      key: 'otherkeypersonnel',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    },
  ];

  const parentNavTabs = [
    {
      name: 'Institution Dashboard',
      key: 'institutionDetails',
      additionalClass: '',
      title: '',
    },
    {
      name: 'Alliance Details',
      key: 'institutionAllianceDetails',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    },
    {
      name: 'Hierarchy',
      key: 'parentCenterhierarchy',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    },
    {
      name: 'Trials at Institution',
      key: 'institutionTrialsatsite',
      additionalClass: '',
      title: '',
    },
    {
      name: 'Institution Metrics',
      key: 'institutionMetrics',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    },
    {
      name: 'Survey',
      key: 'institutionSurvey',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    },
  ]

  if (parse(location.search).currentTab === 'centers') {
    navTabs = [
      ...navTabs,
      ...getCategory().map((d) => {
        return {
          name: d,
          key: d,
          additionalClass: isSponsor() ? 'disabled' : '',
          title: '',
        };
      }),
      {
        name: 'Quality Metrics',
        key: 'quality_metrics',
        additionalClass: isSponsor() ? 'disabled' : '',
        title: '',
      },
    ];
  }

  if (parse(location.search).currentTab === 'parent_centers') {
    navTabs = [
      ...parentNavTabs,
      ...getCategory().filter((f) => f !== 'Institution Dashboard').map((d) => {
        return {
          name: d,
          key: d,
          additionalClass: isSponsor() ? 'disabled' : '',
          title: '',
        };
      }),
      {
        name: 'Compliance',
        key: 'quality_metrics_for_parent',
        additionalClass: isSponsor() ? 'disabled' : '',
        title: '',
      },
    ];
  }

  if (parse(location.search).currentTab === 'centers' && deepDiveData?.data?.address?.country === 'United States') {
    navTabs = [...navTabs, {
      name: 'Diversity',
      key: 'data',
      additionalClass: isSponsor() ? 'disabled' : '',
      title: '',
    }]
  }
  const [activeTab, setActiveTab] = useState(navTabs[0].key);
  const [dateFilter, setDateFilter] = useState(undefined)
  const [openEditModal, setEditCenterModal] = useState(false);
  const [openSiteModal, setSiteModal] = useState(false);
  const [reasonData, setseasonData] = useState('');

  const [investigatorToggle, setInvestigatorToggle] = useState('primary');
  const { search } = history.location;
  const urlSearchParam = new URLSearchParams(search);
  const centerId = urlSearchParam.get('id');

  const createBookmark = useSelector((store) => store.createBookmark);
  const deleteBookmark = useSelector((store) => store.deleteBookmark);
  const markSiteValidInvalidResponse = useSelector((store) => store.markSiteValidInvalidResponse);
  const adminType = getCookie('permissions')
  useEffect(() => {
    if (createBookmark.flag) {
      dispatch(createBookmarkResetAction())
      if (activeTab === 'centerdetails') {
        dispatch(getDeepdiveValuesAction({ id: centerId, dataset: 'centers' }));
      } else if (activeTab === 'institutionDetails') {
        dispatch(getInstituteDeepdiveListAction({ id: centerId }));
      }
    }
    if (deleteBookmark.flag) {
      dispatch(deleteBookmarkResetAction())
      if (activeTab === 'centerdetails') {
        dispatch(getDeepdiveValuesAction({ id: centerId, dataset: 'centers' }));
      } else if (activeTab === 'institutionDetails') {
        dispatch(getInstituteDeepdiveListAction({ id: centerId }));
      }
    }
  }, [JSON.stringify(createBookmark), JSON.stringify(deleteBookmark)])

  useEffect(() => {
    setActiveTab(navTabs[0].key);
    if (parse(location.search).currentTab === 'parent_centers') {
      dispatch(getInstituteDeepdiveListAction({ id: centerId }))
    } else {
      dispatch(getDeepdiveValuesAction({ id: centerId, dataset: 'centers' }));
    }
  }, [parse(location.search).id]);

  useEffect(() => {
    if (parse(location.search).currentTab === 'parent_centers') {
      if (markSiteValidInvalidResponse.flag && markSiteValidInvalidResponse.data.data.message) {
        setSiteModal(false)
        dispatch(getInstituteDeepdiveListAction({ id: centerId }))
      }
    } else if (markSiteValidInvalidResponse.flag && markSiteValidInvalidResponse.data.data.message) {
      setSiteModal(false)
      dispatch(getDeepdiveValuesAction({ id: (parse(location.search).id), dataset: 'centers' }));
    }
  }, [JSON.stringify(markSiteValidInvalidResponse)]);

  const onTabChange = (tab) => {
    setActiveTab(tab.key);
  }
  const tags = [
    { name: 'Tier', size: deepDiveData?.data?.tier },
    { name: 'Total PIs', size: deepDiveData?.data?.total_number_of_popis },
    { name: 'Parexel # studies', size: deepDiveData?.data?.parexel_studies },
    { name: 'Alliance type', size: deepDiveData?.data?.alliance_type ? deepDiveData?.data?.alliance_type : '-' },
    { name: 'Center Type', size: deepDiveData?.data?.center_type ? deepDiveData?.data?.center_type : '-' },
    {
      name: 'Indications',
      size:
        deepDiveData?.data?.indications && deepDiveData?.data?.indications.length
          ? deepDiveData?.data?.indications.join('; ')
          : '-',
    },
    { name: 'Last onsite visit date', size: deepDiveData?.data?.last_onsite_visit_date ? deepDiveData?.data?.last_onsite_visit_date : '-' },
    { name: 'Start-Up Index', size: deepDiveData?.data?.startup_rating ? deepDiveData?.data.startup_rating : '-' },
    { name: 'Start-Up Factor', size: deepDiveData?.data?.startup_factor_average ? deepDiveData?.data.startup_factor_average.toFixed(2) : '-' },
    { name: 'Average Start-up Months', size: deepDiveData?.data?.startup_average ? deepDiveData?.data.startup_average.toFixed(2) : '-' },
    { name: 'SIMS IDs', size: deepDiveData?.data?.pxl_center_sims_ids?.length ? deepDiveData?.data.pxl_center_sims_ids.join(', ') : '-' },
    { name: 'Citeline IDs', size: deepDiveData?.data?.pxl_center_cit_ids?.length ? deepDiveData?.data.pxl_center_cit_ids.join(', ') : '-' },
    { name: 'OMS org IDs', size: deepDiveData?.data?.oms_org_id ? deepDiveData?.data.oms_org_id : '-' },
    { name: 'OMS loc IDs', size: deepDiveData?.data?.oms_loc_id ? deepDiveData?.data.oms_loc_id : '-' },
    { name: 'LandSCAPE ID', size: deepDiveData?.data?.landscape_id ? deepDiveData?.data.landscape_id : '-' },
    { name: 'Department', size: deepDiveData?.data?.department ? deepDiveData?.data.department : '-' },

  ];

  const renderStudies = () => {
    if (studiesData.flag && studiesList.length) {
      return studiesList.map((studyItm, index) => (
        <div className="study-card-wrap" key={index}>
          <div className="card">
            <div className="card-header card-header-notitle card-header-investigator">
              <div className="card-meta">
                <div className="card-meta-footer">
                  <div className="source">
                    <a
                      target="_blank"
                      aria-label="deep-dive"
                      href={`/search/ctdetails?${stringify({
                        ...parse(location.search),
                        id: studyItm.iid,
                        cid: studyItm.clinical_ids,
                        type: studyItm.type,
                      })}`}
                      rel="noopener noreferrer"
                      className="card-ct-link"
                    >
                      <Tooltip title="View Study">
                        <div className="source-img" />
                      </Tooltip>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-title">{studyItm.title}</div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Status:</div>
                <div className="desc">{studyItm.trial_status}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Study start date:</div>
                <div className="desc">{studyItm.date}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Phase:</div>
                <div className="desc">{studyItm.phase}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Trial ID:</div>
                <div className="desc">{studyItm.trial_id}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Clinical ID:</div>
                <div className="desc">{studyItm.clinical_ids.join(', ')}</div>
              </div>
              <div className="card-content-data">
                <div className="title">LandSCAPE ID:</div>
                <div className="desc">{studyItm.landscape_id}</div>
              </div>

              <div className="card-content-data">
                <div className="title">Parexel study ID:</div>
                <div className="desc">{studyItm.pxl_study_no ? studyItm.pxl_study_no : '-'}</div>
              </div>

              <div className="card-content-data">
                <div className="title">Recruitment Factor:</div>
                <div className="desc">{studyItm.trial_srfsa ? studyItm.trial_srfsa : '-'}</div>
              </div>

              <div className="card-content-data">
                <div className="title">Startup Factor:</div>
                <div className="desc">{studyItm.trial_ssfsa ? studyItm.trial_ssfsa : '-'}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Enrolled subjects:</div>
                <div className="desc">{studyItm.site_enrollment_subjects ? studyItm.site_enrollment_subjects : '-'}</div>
              </div>

            </div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Intervention:</div>
                <div className="desc">{studyItm.interventions && studyItm.interventions.length ? studyItm.interventions.join('; ') : '-'}</div>
              </div>
              <div className="card-content-data">
                <div className="title">Indication:</div>
                <div className="desc">{studyItm.indications && studyItm.indications.length ? studyItm.indications.join('; ') : '-'}</div>
              </div>
            </div>
            <div className="card-content-data">
              <div className="title">Sources:</div>
              <div className="desc">{studyItm.sources ? studyItm.sources.join('; ') : '-'}</div>
              {studyItm.total_payments && <div role="presentation" className="view-more">view more</div>}
            </div>
            <div className="card-content">
              <div className="card-content-data">
                <div className="title">Investigators:</div>
                <div className="desc">
                  {studyItm.related_investigators && studyItm.related_investigators.length ? studyItm.related_investigators.join('; ') : '-'}
                </div>
              </div>
              <div className="card-content-data">
                <div className="title">Sponsor:</div>
                <div className="desc">{studyItm.sponsors && studyItm.sponsors.length ? studyItm.sponsors.join('; ') : '-'}</div>
              </div>
            </div>
          </div>
        </div>
      ))
    }
    if (studiesData.flag && !studiesList.length) {
      return (
        <div className="no-data-found-msg">
          No relevant data found
        </div>
      );
    }
    return null;
  }
  const handleExportCenterClick = () => {
    setExportLoading(true)
    const input = document.getElementById('center-profile');
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const imgWidth = 190;
        const pageHeight = 290;
        const imgHeight = (canvas.height * imgWidth) / canvas.width;
        let heightLeft = imgHeight;
        // eslint-disable-next-line new-cap
        const doc = new jsPDF('pt', 'mm');
        let position = 0;
        doc.addImage(imgData, 'PNG', 10, 0, imgWidth, imgHeight + 25);
        heightLeft -= pageHeight;
        while (heightLeft >= 0) {
          position = heightLeft - imgHeight;
          doc.addPage();
          doc.addImage(imgData, 'PNG', 10, position, imgWidth, imgHeight + 25);
          heightLeft -= pageHeight;
        }
        doc.save(`Center_profile-${exportCenterModal.center_name}`);
        setExportLoading(false)
        setExportCenterModal(false)
      })
  };
  const renderActiveTabContent = () => {
    const staticComp = ['centerdetails', 'institutionDetails', 'institutionAllianceDetails', 'institutionMetrics', 'principalinvestigators', 'trialsatsite', 'institutionTrialsatsite', 'otherkeypersonnel', 'data', 'survey', 'networks', 'parentCenterhierarchy', 'centerhierarchy', 'institutionSurvey', 'quality_metrics', 'quality_metrics_for_parent'];
    if (staticComp.includes(activeTab)) {
      switch (activeTab) {
        case 'centerdetails': {
          return (
            <CenterDetails />
          )
        }
        case 'institutionDetails': {
          return (
            <InstitutionDetails />
          )
        }
        case 'institutionAllianceDetails': {
          return (
            <InstitutionAllianceDetails />
          )
        }

        case 'institutionSurvey': {
          return (
            <CenterSurvey flag="PARENT_SITE" showques allianceType={typeOfData} dateFilter={dateFilter} />
          )
        }

        case 'institutionMetrics': {
          return (
            <InstitutionMetrics allianceType={typeOfData} dateFilter={dateFilter} />
          )
        }
        case 'principalinvestigators': {
          return (
            <DocumentList activeDataset="investigators" query="" centerId={centerId} centerType={investigatorToggle} />
          )
        }
        case 'institutionTrialsatsite': {
          return (
            <InstituteTrialsAtSite flag="PARENT_SITE" allianceType={typeOfData} centerName={parse(location.search).currentTab === 'parent_centers' ? instituteDeepdiveList?.data?.data?.name : deepDiveData?.data?.name} dateFilter={dateFilter} />
          )
        }

        case 'trialsatsite': {
          return (
            <TrialsAtSite />
          )
        }
        case 'otherkeypersonnel': {
          return (
            <CentersOtherKeyPersonal data={deepDiveData} />
          )
        }
        case 'data': {
          return (
            <DashboardData flag="site" />
          )
        }

        case 'survey': {
          return (
            <CenterSurvey flag="SITE" showques={false} />
          )
        }

        case 'networks': {
          return (
            <CenterNetwork />
          )
        }
        case 'centerhierarchy': {
          return (
            <InvestigatorHierarchy type="centers" data={deepDiveData} />
          )
        }
        case 'parentCenterhierarchy': {
          return (
            <ParentCenterHierarchy type="centers" data={deepDiveData} allianceType={typeOfData} dateFilter={dateFilter} />
          )
        }
        case 'quality_metrics': {
          return (
            <QualityMetricsForCenters complianceData={deepDiveData} />
          )
        }

        case 'quality_metrics_for_parent': {
          return (
            <ParentCompliance complianceData={deepDiveData} />
          )
        }
        default: {
          return (
            <div className="no-data-found-msg">
              No relevant data found
            </div>
          );
        }
      }
    }
    let data = []
    if (parse(location.search).currentTab === 'parent_centers') {
      data = instituteDeepdiveList?.data?.data?.profile_responses?.filter(
        (pr) => pr.parent_category === activeTab,
      );
    } else {
      data = deepDiveData.data.profile_responses.filter(
        (pr) => pr.child_category === activeTab,
      );
    }

    if (parse(location.search).currentTab === 'parent_centers') {
      return data?.length > 0 && data?.some((e) => e.parent_category === 'Facilities') ? (
        <CenterFacilities data={data} />
      ) : data?.length > 0 && data?.some((e) => e.parent_category !== 'Facilities') ? (
        <CenterPatientAccess data={data} />
      )
        : <div className="no-data-found-msg">No relevant data found</div>
    }
    return data?.length > 0 && data?.some((e) => e.child_category === 'Facilities') ? (
      <CenterFacilities data={data} />
    ) : data?.length > 0 && data?.some((e) => e.child_category !== 'Facilities') ? (
      <CenterPatientAccess data={data} />
    )
      : <div className="no-data-found-msg">No relevant data found</div>
  }

  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1
    }
    if (value === 'Class 2') {
      return 2
    }
    if (value === 'Class 1') {
      return 3
    }
    return 0
  }

  const onEditBtnClick = () => {
    dispatch(updateSiteResetAction())
    dispatch(updateParentResetAction())
    setEditCenterModal(true)
  }

  const handleCancel = () => {
    setEditCenterModal(false)
    dispatch(getStateDataResetAction())
  };

  const handleSiteCancel = () => {
    setSiteModal(false)
  };

  const onValidInvalidBtnClick = () => {
    setSiteModal(true)
  }

  const onTextChange = (e) => {
    setseasonData(e.target.value)
  }

  const markInvalid = () => {
    dispatch(markSiteValidInvalidAction({
      params: {
        site_id: parse(location.search).id,
      },
      body: {
        is_invalid: {
          old: false,
          new: true,
        },
        is_invalid_reason: {
          old: deepDiveData?.data?.is_invalid_reason,
          new: reasonData,
        },
      },
    },
    {
      markInvalid: true,
    }))
    const params = {
      site_id: parse(location.search).id,
    }
    setAnalytics('centerDeepDiveMarkSiteAsInvalid', 'data', params)
  }

  const markValid = () => {
    dispatch(markSiteValidInvalidAction({
      params: {
        site_id: parse(location.search).id,
      },
      body: {
        is_invalid: {
          old: true,
          new: false,
        },
        is_invalid_reason: {
          old: deepDiveData?.data?.is_invalid_reason,
          new: reasonData,
        },
      },
    },
    {
      markInvalid: false,
    }))
    const params = {
      site_id: parse(location.search).id,
    }
    setAnalytics('centerDeepDiveMarkSiteAsValid', 'data', params)
  }

  const getDashboardDetails = () => {
    return (
      <div className="card">
        <div className="content deepdive-content">
          <div className="right-side">
            <div style={{ display: 'flex' }}>
              <div style={{ color: '#848484' }}> Parent Institution Name: </div>
              {deepDiveData?.data?.parent_institution && Object.keys(deepDiveData?.data?.parent_institution).length ? (
                <a
                  target="_blank"
                  href={`/search/deepdives?${stringify({
                    ...parse(location.search),
                    id: deepDiveData?.data?.parent_institution?.id,
                    currentTab: 'parent_centers',
                  })}`}
                  rel="noopener noreferrer"
                >&nbsp;{deepDiveData?.data?.parent_institution?.name}
                </a>
              ) : (
                <span className="value" style={{ fontSize: '14px' }}>&nbsp;No data available</span>
              )}
            </div>
            <div className="tags-data-content">
              {tags.map((it, idx) => (
                <Tag key={idx}>
                  <div className="details-abt-tags">
                    {it.name === 'Indications' ? (
                      <>
                        <div className="nameOfTag">{it.name}</div>
                        <Tooltip placement="bottom" title={it.size}>
                          {deepDiveData?.data.indications
                            && deepDiveData?.data.indications.length > 1 ? (
                              <span className="sizeOftag">
                                {it.size}
                              </span>
                          ) : (
                            <span className="sizeOftag">{it.size}</span>
                          )}
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        <div className="nameOfTag">{it.name}</div>
                        <Tooltip placement="bottom" title={it.size}>
                          {it.name === 'Tier' ? <div className="sizeOftag tier-wrap">{it.size}</div> : <div className="sizeOftag">{it.size}</div>}
                        </Tooltip>
                      </>
                    )}
                  </div>
                </Tag>
              ))}
            </div>
            <div className="reliability-quality-rating">
              <Tooltip title={deepDiveData?.data.reliability ? `Reliability score : ${deepDiveData?.data.reliability}` : '-'}>
                <div className="reliability rating-sec">
                  <div className="rating-sec-title">Reliability : </div>
                  {deepDiveData?.data.reliability_class ? (
                    <StarRatings
                      rating={getStars(deepDiveData?.data.reliability_class)}
                      starRatedColor="#8A0050"
                      numberOfStars={3}
                      starDimension="16px"
                      starSpacing="1px"
                      starEmptyColor="#D099B9"
                    />
                  ) : (
                    <div>-</div>
                  )}
                </div>
              </Tooltip>
              <div className="quality rating-sec">
                <div className="rating-sec-title"> Quality : </div>
                {deepDiveData?.data.quality ? (
                  <StarRatings
                    rating={deepDiveData?.data.quality}
                    starRatedColor="#8A0050"
                    numberOfStars={3}
                    starDimension="16px"
                    starSpacing="1px"
                    starEmptyColor="#D099B9"
                  />
                ) : (
                  <div>-</div>
                )}
              </div>
            </div>
          </div>
          <div className="leftside">
            <div className="person-details">
              <div className="address-recent">
                <div className="title-child">Address:</div>
                {deepDiveData?.data?.address ? (
                  <Tooltip
                    placement="bottom"
                    title={(
                      <div className="content-child">
                        {deepDiveData?.data.address?.address_line1 && `${deepDiveData?.data.address?.address_line1}, `}
                        {deepDiveData?.data.address?.address_line2 && `${deepDiveData?.data.address?.address_line2}, `}
                        {deepDiveData?.data.address?.city && `${deepDiveData?.data.address?.city}, `}
                        {deepDiveData?.data.address?.state && `${deepDiveData?.data.address?.state}, `}
                        {deepDiveData?.data.address?.country && `${deepDiveData?.data.address?.country}, `}
                        {deepDiveData?.data.address?.zip}
                      </div>
                    )}
                  >
                    <div className="content-child address-field-content">
                      {deepDiveData?.data.address?.address_line1 && `${deepDiveData?.data.address?.address_line1}, `}
                      {deepDiveData?.data.address?.address_line2 && `${deepDiveData?.data.address?.address_line2}, `}
                      {deepDiveData?.data.address?.city && `${deepDiveData?.data.address?.city}, `}
                      {deepDiveData?.data.address?.state && `${deepDiveData?.data.address?.state}, `}
                      {deepDiveData?.data.address?.country && `${deepDiveData?.data.address?.country}, `}
                      {deepDiveData?.data.address?.zip}
                    </div>
                  </Tooltip>

                ) : (
                  <div className="content-child no-data">No data available</div>
                )}
              </div>
              {/* <div className="emailofcontact">
              <div className="title-child">Email:</div>
              {deepDiveData?.data.email && deepDiveData?.data.email.length ? (
                <Tooltip placement="bottom" title={deepDiveData?.data.email.join(', ')}>
                  <div className="content-child">
                    <div className="content-child-title">{deepDiveData?.data.email.join(', ')}</div>
                    <InputWithCopy text={deepDiveData?.data} type="email" />
                  </div>
                </Tooltip>
              ) : (
                <div className="content-child no-data">No data available</div>
              )}
            </div> */}
              <div className="mobileOfcontact">
                <div className="title-child">Phone:</div>
                {deepDiveData?.data.phone_number && deepDiveData?.data.phone_number.length ? (
                  <Tooltip placement="bottom" title={deepDiveData?.data.phone_number.join(', ')}>
                    <div className="content-child">{deepDiveData?.data.phone_number.join(', ')}</div>
                  </Tooltip>
                ) : (
                  <div className="content-child no-data">No data available</div>
                )}
              </div>
              <div className="mobileOfcontact">
                <div className="title-child">Fax Number:</div>
                {deepDiveData?.data.fax && deepDiveData?.data.fax.length ? (
                  <Tooltip placement="bottom" title={deepDiveData?.data.fax.join(', ')}>
                    <div className="content-child">{deepDiveData?.data.fax.join(', ')}</div>
                  </Tooltip>
                ) : (
                  <div className="content-child no-data">No data available</div>
                )}
              </div>
              <div className="mobileOfcontact">
                <div className="title-child">URL:</div>
                {deepDiveData?.data.url ? (
                  <Tooltip placement="bottom" title={deepDiveData?.data.url}>
                    <div className="content-child">{deepDiveData?.data.url}</div>
                  </Tooltip>
                ) : (
                  <div className="content-child no-data">No data available</div>
                )}
              </div>
              {/* <div className="mobileOfcontact">
              <div className="title-child">Mobile number:</div>
              {deepDiveData?.data.mobile_number && deepDiveData?.data.mobile_number.length ? (
                <Tooltip placement="bottom" title={deepDiveData?.data.mobile_number.join(', ')}>
                  <div className="content-child">{deepDiveData?.data.mobile_number.join(', ')}</div>
                </Tooltip>
              ) : (
                <div className="content-child no-data">No data available</div>
              )}
            </div> */}
            </div>
          </div>
        </div>
      </div>
    )
  }
  const getPIList = () => {
    return listData && listData.length && listData.map((item, index) => (
      <Template type={item.type} itm={item} index={index} />
    ))
  }
  const renderDiv = (itm) => {
    return (
      <div className="center-facilities">
        <div className="center-facilities-title">{itm.question_text}</div>
        <div className="center-facilities-content">
          <div className="center-facilities-content-card card">
            <div className="center-facilities-content-card-content">
              <div className="content-value">
                <div className="content-value-desc">{itm.response[0]}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  const renderCenterFacilities = () => {
    let data = []
    data = deepDiveData.data.profile_responses;
    const headers = ['Clinic', 'Pharmacy', 'Lab', 'Radiology', 'Telehealth', 'Ophthalmology', 'Surgery', 'TA specific', 'Facility', 'Decentralized Clinical Trials', 'Vaccine Administration & Management']
    return headers.map((i) => {
      return data.filter((f) => (parse(location.search).currentTab === 'parent_centers' ? f.parent_sub_category : f.child_sub_category === i)).length
        ? data.filter((f) => (parse(location.search).currentTab === 'parent_centers' ? f.parent_sub_category : f.child_sub_category === i)).map((itm) => {
          return (
            <>
              {renderDiv(itm)}
            </>
          )
        }) : null
    })
  }

  const getCenterExportDiv = () => {
    return (
      <Loader loading={deepDiveData.loading} error={deepDiveData.error}>
        <div className="countryname-heading">
          {deepDiveData?.data?.name}
        </div>
        <div className="modified-content">
          <div className="modified-content-name">Last modified by: {deepDiveData?.data?.last_modified_by_user_name ? deepDiveData?.data?.last_modified_by_user_name : '-'}</div>
          <div className="modified-content-date">Last modified on: {deepDiveData?.data?.last_modified_on ? getDatefromTimestamp(deepDiveData?.data?.last_modified_on) : '-'}</div>
        </div>
        <div className="export-investigator-title">
          Dashboard Details
        </div>
        {getDashboardDetails()}
        <div className="export-investigator-title">
          Studies (Upto latest 10)
        </div>
        <Loader loading={studiesData.loading} error={studiesData.error}>
          {renderStudies()}
        </Loader>
        <div className="export-investigator-title">
          Principal Investigators (top 10)
        </div>
        <Loader loading={listData.loading} error={listData.error}>
          {getPIList()}
        </Loader>
        <div className="export-investigator-title export-investigator-title-last">
          Center Facilities
        </div>
        {renderCenterFacilities()}
      </Loader>
    )
  }

  const getDatefromTimestamp = (d) => {
    return new Date(d * 1000).toUTCString().substring(4, 16)
  }

  const onAliasClick = () => {
    setEditAliasesFlag(false);
    setAlias(true)
  }

  const onHandleChange = (e) => {
    setInputValueForAlias(e.target.value);
  };

  const updateAliasName = () => {
    if (parse(location.search).currentTab === 'centers') {
      const updateTemp = {
        name: {
          old: deepDiveData?.data?.name,
          new: deepDiveData?.data?.name,
          flag: false,
        },
        pxl_aliases: {
          old: defaultValueOfAlias,
          new: inputValueForAlias.split('\n').map((name) => name.trim()),
          flag: true,
        },
      }
      dispatch(updateSiteAction({
        params: {
          site_id: centerId,
          direct_edit: true,
          duplicate_update: false,
        },
        body: updateTemp,
      }))
    } else {
      const updateTemp = {
        name: {
          old: instituteDeepdiveList?.data?.data?.name,
          new: instituteDeepdiveList?.data?.data?.name,
          flag: false,
        },
        pxl_aliases: {
          old: defaultValueOfAlias,
          new: inputValueForAlias.split('\n').map((name) => name.trim()),
          flag: true,
        },
      }
      dispatch(updateParentAction({
        params: {
          site_id: instituteDeepdiveList?.data?.data?.id,
          direct_edit: true,
          duplicate_update: false,
        },
        body: updateTemp,
      }))
    }
  }

  const editNameDisabled = () => {
    if (deepDiveDataResponse?.data?.name?.startsWith('Investigators Without Primary Org')) {
      return true
    }
    return false
  }

  const isEditDisabled = () => {
    if (parse(location.search).currentTab === 'parent_centers') {
      if (adminType?.includes('sfl_access') || adminType?.includes('sam_access') || adminType?.includes('super_admin')) {
        return false
      }
      return true
    }
    return false
  }

  const disableToggle = () => {
    return activeTab === 'institutionDetails' || activeTab === 'institutionAllianceDetails' || activeTab === 'Quality incl. Compliance' ? 'disable-toggle' : ''
  }

  const disableDates = () => {
    return activeTab === 'institutionDetails' || activeTab === 'institutionAllianceDetails' || activeTab === 'Quality incl. Compliance' ? 'disable-toggle' : ''
  }

  const handleDateChange = (e, type) => {
    setDateError(false)
    if (type === 'start_date') {
      if (e === null) {
        if (dateFilter[1]) {
          setDateFilter([0, dateFilter[1]])
        } else {
          setDateFilter(undefined)
        }
      } else {
        const startDate = moment(e._d).unix();
        if (dateFilter !== undefined) {
          if (dateFilter[1]) {
            setDateFilter([
              parseInt(startDate / 86400, 10) * 86400,
              dateFilter[1],
            ])
          } else {
            setDateFilter([
              parseInt(startDate / 86400, 10) * 86400,
            ])
          }
        } else {
          setDateFilter([
            parseInt(startDate / 86400, 10) * 86400,
          ])
        }
        if (type === 'start_date' && dateFilter[1]) {
          if (startDate > dateFilter[1]) {
            setDateError(true)
          }
        }
      }
    } else if (type === 'end_date') {
      if (e === null) {
        if ((dateFilter[0] && dateFilter[0] !== null) || (dateFilter[0] && dateFilter[0] !== undefined) || (dateFilter[0] && dateFilter[0] !== 0)) {
          setDateFilter([dateFilter[0]])
        } else {
          setDateFilter(undefined)
        }
      } else {
        const endDate = moment(e._d).unix();
        if (dateFilter !== undefined) {
          setDateFilter([
            dateFilter[0],
            parseInt(endDate / 86400, 10) * 86400 + 86399,
          ])
        } else {
          setDateFilter([
            0,
            parseInt(endDate / 86400, 10) * 86400 + 86399,
          ])
        }
        if (type === 'end_date' && dateFilter[0]) {
          if (dateFilter[0] > endDate) {
            setDateError(true)
          }
        }
      }
    }
  }

  const onCancel = () => {
    setAlias(false)
    if (parse(location.search).currentTab === 'parent_centers') {
      if (instituteDeepdiveList?.data?.data?.pxl_aliases.length) {
        setInputValueForAlias(instituteDeepdiveList?.data?.data?.pxl_aliases.join('\n'))
      } else {
        setInputValueForAlias('')
      }
    }
    if (parse(location.search).currentTab === 'centers') {
      if (deepDiveDataResponse?.data?.pxl_aliases.length) {
        setInputValueForAlias(deepDiveDataResponse?.data?.pxl_aliases.join('\n'))
      } else {
        setInputValueForAlias('')
      }
    }
  }

  const renderComplianceFlag = (centerQuality) => {
    if (centerQuality === 'Compliance not preventing participation'
        || centerQuality === 'Approved with Warning') {
      return 'compliance-flag-icon preventing';
    } if (centerQuality === 'Compliance preventing participation'
               || centerQuality === 'DNU') {
      return 'compliance-flag-icon not-preventing';
    }
    return 'heading';
  };

  return (
    <div className="whole-deep-dive-page">
      <div className="countryname-heading">
        <Tooltip title={(
          <div>
            <div>Last modified by: {deepDiveData?.data?.last_modified_by_user_name ? deepDiveData?.data?.last_modified_by_user_name : '-'}</div>
            <div>Last modified on: {deepDiveData?.data?.last_modified_on ? getDatefromTimestamp(deepDiveData?.data?.last_modified_on) : '-'}</div>
          </div>
      )}
        >
          {parse(location.search).currentTab === 'parent_centers' ? instituteDeepdiveList?.data?.data?.name : deepDiveData?.data?.name}
          {(deepDiveDataResponse.flag || instituteDeepdiveList.flag) && (
          <Button className="heading-aliases" type="link" onClick={onAliasClick}>
            aliases
          </Button>
          )}
        </Tooltip>
        {parse(location.search).currentTab === 'parent_centers' ? (
          <div className={renderComplianceFlag(instituteDeepdiveList?.data?.data?.center_quality)} />
        )
          : (
            <>
              <Tooltip title={`${deepDiveData?.data?.center_quality} ${deepDiveData?.data?.center_quality_inherit_flag === true ? '(Inherited from parent)' : ''}`}>
                <div className={renderComplianceFlag(deepDiveData?.data?.center_quality)} />
              </Tooltip>
            </>
          )}
      </div>

      {showAlias && (
        <Modal
          visible={showAlias}
          className="aliases-sec-modal"
          onCancel={() => onCancel()}
          title={(
            <div className="alias-content-modal">
              <div className="alias-text">Aliases</div>
              {!editAliasesFlag && (
                <Tooltip title="Edit Alias">
                  <div className="edit-btn-for-alias">
                    <Button onClick={() => setEditAliasesFlag(true)}>
                      <EditOutlined />
                    </Button>
                  </div>
                </Tooltip>
              )}
            </div>
          )}
          destroyOnClose
          footer={null}
        >
          <Loader loading={updateSiteResponse.loading || updateParentResponse.loading}>
            {editAliasesFlag ? (
              <div className="del-pi-text">
                <div className="aliases-sec">
                  <TextArea
                    rows={4}
                    value={inputValueForAlias}
                    onChange={(e) => onHandleChange(e)}
                    style={{ resize: 'none' }}
                  />
                </div>
                <div
                  className="text-area-mark-valid-invalid-sec-footer"
                  style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <Button
                    className="done-btn"
                    type="primary"
                    onClick={() => {
                      setEditAliasesFlag(false);
                    }}
                    style={{ marginRight: '10px' }}
                  >
                    {' '}
                    Cancel
                  </Button>
                  <Button
                    className="done-btn"
                    type="primary"
                    onClick={() => {
                      updateAliasName();
                    }}
                  >
                    {' '}
                    Update
                  </Button>
                </div>
              </div>
            ) : (
              <div className="del-pi-text">
                <div className="aliases-sec">
                  {parse(location.search).currentTab === 'parent_centers' && instituteDeepdiveList?.data?.data?.pxl_aliases.length
                    ? instituteDeepdiveList?.data?.data?.pxl_aliases.map((i) => {
                      return <div className="aliases-sec-text"> {i} </div>
                    })
                    : parse(location.search).currentTab === 'centers' && deepDiveData?.data?.pxl_aliases.length
                      ? deepDiveData?.data?.pxl_aliases.map((i) => {
                        return <div className="aliases-sec-text"> {i} </div>
                      })
                      : <div>No data found</div>}
                </div>
              </div>
            )}
          </Loader>
        </Modal>
      )}
      <div className="outer_large_div">
        <div className="nav-tab-bar">
          <div className="tags-area">
            <TagsBar
              activeTab={activeTab}
              tags={navTabs}
              onTabChange={onTabChange}
              showInvestigatorToggle={activeTab === 'principalinvestigators'}
              onToggleChange={(val) => setInvestigatorToggle(val)}
            />
            {((parse(location.search).currentTab === 'centers')
              && (
                <div className="export-btn-center">
                  <Button
                    type="primary"
                    onClick={() => {
                      setExportCenterModal({ isOpen: true, center_name: deepDiveData?.data?.name ? deepDiveData?.data?.name.replace(/\s+/g, '_') : 'Unknown' })
                    }}
                    disabled={!deepDiveData?.data?.id || isSponsor()}
                  />
                </div>
              )
            )}
            {
              ((parse(location.search).currentTab === 'centers' && deepDiveData?.data?.is_invalid === true)
              && (
                <Tooltip title={`This SITE is marked as Invalid. Reason: ${deepDiveData?.data?.is_invalid_reason}`}>
                  <div className="invalid-heading" style={{ right: '200px' }} />
                </Tooltip>
              )
            )
}

            {
              ((parse(location.search).currentTab === 'parent_centers' && instituteDeepdiveList?.data?.data?.is_invalid === true)
              && (
                <Tooltip title={`This Parent Institution is marked as Invalid. Reason: ${instituteDeepdiveList?.data?.data?.is_invalid_reason}`}>
                  <div className="invalid-heading" style={{ right: '445px' }} />
                </Tooltip>
              )
            )
}

            <Tooltip
              title={parse(location.search).currentTab === 'centers' ? 'Edit Center' : 'Edit Parent Institute'}
            >
              {parse(location.search).currentTab === 'centers' ? (
                <div className="edit-btn">
                  <Button disabled={isEditDisabled() || instituteDeepdiveList.loading || deepDiveDataResponse.loading || isSponsor() || editNameDisabled()} onClick={onEditBtnClick}><EditOutlined /></Button>
                </div>
              ) : (
                <div className="edit-btn">
                  <Button disabled={isEditDisabled() || instituteDeepdiveList.loading || deepDiveDataResponse.loading || isSponsor()} onClick={onEditBtnClick}><EditOutlined /></Button>
                </div>
              )}

            </Tooltip>
            {((parse(location.search).currentTab === 'centers') && deepDiveData?.data?.is_invalid === true) ? (
              <div className={`valid-invalid-btn ${isSponsor() ? 'disabled' : ''}`}>
                <Tooltip title={`This SITE is marked as Invalid. Reason: ${deepDiveData?.data?.is_invalid_reason}`}>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (!isSponsor()) {
                        onValidInvalidBtnClick()
                      }
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <div className={`valid-invalid-btn ${isSponsor() ? 'disabled' : ''}`}>
                <Button
                  type="primary"
                  onClick={() => {
                    if (!isSponsor()) {
                      onValidInvalidBtnClick()
                    }
                  }}
                />
              </div>
            )}
            {((parse(location.search).currentTab === 'parent_centers') && instituteDeepdiveList?.data?.data?.is_invalid === true) ? (
              <div className={`valid-invalid-btn ${isSponsor() ? 'disabled' : ''}`}>
                <Tooltip title={`This Parent Institution is marked as Invalid. Reason: ${instituteDeepdiveList?.data?.data?.is_invalid_reason}`}>
                  <Button
                    type="primary"
                    onClick={() => {
                      if (!isSponsor()) {
                        onValidInvalidBtnClick()
                      }
                    }}
                  />
                </Tooltip>
              </div>
            ) : (
              <div
                className={`valid-invalid-btn ${isSponsor() ? 'disabled' : ''}`}
                role="presentation"
                onClick={() => {
                  if (isSponsor()) {
                    onValidInvalidBtnClick()
                  }
                }}
              />
            )}

            {(parse(location.search).currentTab === 'parent_centers') && (
              <>
                <div className="toggle-switch-wrap">
                  <div
                    className={`toggle-switch-itm ${typeOfData === 'All' ? 'active' : ''} ${disableToggle()}`}
                    role="presentation"
                    onClick={() => (disableToggle() ? '' : setTypeOfData('All'))}
                  >
                    All
                  </div>
                  <div
                    className={`toggle-switch-itm ${typeOfData === 'Alliance' ? 'active' : ''} ${disableToggle()}`}
                    role="presentation"
                    onClick={() => (disableToggle() ? '' : setTypeOfData('Alliance'))}
                  >
                    Alliance
                  </div>
                  <div
                    className={`toggle-switch-itm ${typeOfData === 'Non-Alliance' ? 'active' : ''} ${disableToggle()}`}
                    role="presentation"
                    onClick={() => (disableToggle() ? '' : setTypeOfData('Non-Alliance'))}
                  >
                    Non-Alliance
                  </div>
                </div>
                <Popover
                  placement="bottom"
                  title="Date Filter"
                  trigger="click"
                  overlayClassName="calender-filter-popover"
                  content={(
                    <div className="date-filter" style={{ display: 'flex', flexDirection: 'column' }}>
                      <div className="date-filter" style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className="date-filter-start-date">
                          <div className="date-filter-start-date-label">
                            Start Date
                          </div>
                          <div className="date-filter-start-date-label">
                            <DatePicker
                              disabled={disableDates()}
                              placeholder="Start Date"
                              onChange={(e) => handleDateChange(e, 'start_date')}
                            // disabledDate={(d) => !d || d.isAfter('2020-12-31') || d.isSameOrBefore('1960-01-01')}
                            />
                          </div>
                        </div>
                        <div className="date-filter-start-date">
                          <div className="date-filter-start-date-label">
                            End Date
                          </div>
                          <div className="date-filter-start-date-label">
                            <DatePicker disabled={disableDates()} placeholder="End Date" onChange={(e) => handleDateChange(e, 'end_date')} />
                          </div>
                        </div>
                      </div>
                      {dateError && <div className="error-msg">End date cannot be earlier than the start date</div>}
                    </div>
                  )}
                >
                  <div className="calender-date-filter"><Button title={`${disableDates() ? 'Date filters are not supported for this tab' : 'Date filter'}`} disabled={disableDates()} /></div>
                </Popover>
                {/* <Button onClick={() => setDateFilterPopOver(!dateFilterPopOver)}>Calender</Button> */}
              </>
            )}

            <div className="profile-actions">
              <div className="comments">
                <Comment type="list_site" siteId={deepDiveData?.data?.id} count={JSON.stringify(deepDiveData?.data?.comments)} />
              </div>
              <div className="fav">
                <Bookmark isBookmarked={deepDiveData?.data?.isBookmarked && deepDiveData?.data?.isBookmarked.flag} type="centers" id={deepDiveData?.data?.id} bookmark_id={deepDiveData?.data?.isBookmarked && deepDiveData?.data?.isBookmarked.bookmark_id} />
              </div>
            </div>
          </div>
          {openEditModal && (!deepDiveData.loading && !deepDiveData.error) && (
            <Modal
              title={parse(location.search).currentTab !== 'parent_centers' ? 'Edit Center' : 'Edit Parent Institution'}
              visible={openEditModal}
              onCancel={handleCancel}
              className="modal-wrapper add-investigator-modal"
              maskClosable={false}
              destroyOnClose
              footer={null}
            >
              <div
                className="add-investigator-section add-investigator-section-lvl-up"
              >
                {(parse(location.search).currentTab !== 'parent_centers') ? <AddCentersManuallyAddNew flag="editCenter" setDefaultValues setEditCenterModal={setEditCenterModal} /> : <AddEditParentInstitute flag="editCenter" setDefaultValues setEditCenterModal={setEditCenterModal} />}
              </div>
            </Modal>
          )}

          {(openSiteModal && ((parse(location.search).currentTab !== 'parent_centers' && deepDiveData?.data?.is_invalid === false) || (parse(location.search).currentTab === 'parent_centers' && instituteDeepdiveList?.data?.data?.is_invalid === false)))
            && (
              <Modal
                title="Mark SITE as Invalid"
                visible={openSiteModal}
                onCancel={handleSiteCancel}
                className="modal-wrapper add-investigator-modal mark-valid-invalid-pi-modal"
                maskClosable={false}
                destroyOnClose
                footer={null}
              >
                <Loader loading={markSiteValidInvalidResponse.loading} error={markSiteValidInvalidResponse.error}>

                  <div
                    className="add-investigator-section add-investigator-section-lvl-up"
                  >
                    <div className="text-area-mark-valid-invalid-sec">
                      <TextArea rows={4} placeholder="Enter reason" onChange={(e) => onTextChange(e)} />
                    </div>

                    <div className="text-area-mark-valid-invalid-sec-footer">
                      <Button className="done-btn" type="primary" onClick={() => markInvalid()}> Done </Button>
                    </div>

                  </div>
                </Loader>
              </Modal>
            )}
          {(openSiteModal
            && ((parse(location.search).currentTab !== 'parent_centers' && deepDiveData?.data?.is_invalid === true) || (parse(location.search).currentTab === 'parent_centers' && instituteDeepdiveList?.data?.data?.is_invalid === true))) && (
              <Modal
                title="Mark SITE as Valid"
                visible={openSiteModal}
                onCancel={handleSiteCancel}
                className="modal-wrapper add-investigator-modal mark-valid-invalid-pi-modal"
                maskClosable={false}
                destroyOnClose
                footer={null}
              >
                <Loader loading={markSiteValidInvalidResponse.loading} error={markSiteValidInvalidResponse.error}>

                  <div
                    className="add-investigator-section add-investigator-section-lvl-up"
                  >
                    <div className="text-area-mark-valid-invalid-sec">
                      <TextArea rows={4} placeholder="Enter reason" onChange={(e) => onTextChange(e)} />
                    </div>
                    <div className="text-area-mark-valid-invalid-sec-footer">
                      <Button className="done-btn" type="primary" onClick={() => markValid()}> Done </Button>
                    </div>
                  </div>
                </Loader>
              </Modal>
          )}
          {exportCenterModal.isOpen && (
            <Modal
              title="Export Center"
              visible={exportCenterModal.isOpen}
              className="profile-export-modal"
              onCancel={() => setExportCenterModal({ isOpen: false, center_name: '' })}
              maskClosable={false}
              destroyOnClose
              footer={null}
            >
              <div className="export-investigator-profile" id="center-profile">
                <Loader loading={exportLoading}>
                  {getCenterExportDiv()}
                </Loader>
              </div>
              <div className="footer-btn">
                <Button
                  className="invalid-button"
                  type="primary"
                  onClick={() => handleExportCenterClick()}
                  disabled={exportLoading}
                >Export
                </Button>
                <Button
                  className="invalid-button"
                  type="primary"
                  onClick={() => setExportCenterModal({ isOpen: false, center_name: '' })}
                  disabled={exportLoading}
                >Cancel
                </Button>
              </div>
            </Modal>
          )}
          <>
            {renderActiveTabContent()}
          </>
        </div>
      </div>
    </div>
  );
};

DeepDives.propTypes = {};

export default DeepDives;
