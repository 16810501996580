import { Table } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { parse } from 'query-string';
import { getInstituteDeepdiveListAction } from '../InstitutionDetails/logic';

export default function InstitutionAllianceDetails() {
  const dispatch = useDispatch();

  const { id } = parse(location.search)

  const instituteDeepdiveList = useSelector((store) => store.instituteDeepdiveList);
  const [instituteDeepDiveData, setInstituteDeepDiveData] = useState([]);

  useEffect(() => {
    dispatch(getInstituteDeepdiveListAction({ id }))
  }, []);

  useEffect(() => {
    if (instituteDeepdiveList.flag) {
      setInstituteDeepDiveData(instituteDeepdiveList.data.data)
    }
  }, [JSON.stringify(instituteDeepdiveList)])
  const columns = [
    {
      title: 'Role',
      dataIndex: 'role',
    },
    {
      title: 'Role at Site',
      dataIndex: 'role_at_site',
    },
    {
      title: 'Name',
      dataIndex: 'full_name',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'mobile_number',
    },
    {
      title: 'Fax Number',
      dataIndex: 'fax',
    },
  ];

  return (
    <div className="alliance-header">
      <div className="tab-ques-wrap-content institution-dashboard-ques-wrap">
        <div className="tab-ques-wrap-content-card institution-dashboard-ques-wrap-card">
          <div className="institution-dashboard-ques-wrap-card-container">
            {instituteDeepDiveData.parent_alliance
              && (
                <>
                  <div className="institution-dashboard-ques-wrap-card-container-content">
                    <div className="key-wrap-title">Alliance Type</div>
                    <div className="value-wrap-desc">{instituteDeepDiveData.parent_alliance.alliance_type ? instituteDeepDiveData.parent_alliance.alliance_type : '-'}</div>
                  </div>
                  <div className="institution-dashboard-ques-wrap-card-container-content">
                    <div className="key-wrap-title">Alliance Member Name</div>
                    <div className="value-wrap-desc">{instituteDeepDiveData.parent_alliance.alliance_member_name ? instituteDeepDiveData.parent_alliance.alliance_member_name : '-'}</div>
                  </div>
                  <div className="institution-dashboard-ques-wrap-card-container-content">
                    <div className="key-wrap-title">Interest in Site Alliance program</div>
                    <div className="value-wrap-desc">{instituteDeepDiveData.parent_alliance.interest_in_alliance_program ? instituteDeepDiveData.parent_alliance.interest_in_alliance_program : '-'}</div>
                  </div>
                  <div className="institution-dashboard-ques-wrap-card-container-content">
                    <div className="key-wrap-title">Parexel recommendation for partnering</div>
                    <div className="value-wrap-desc">{instituteDeepDiveData.parent_alliance.is_pxl_recomd_partnering ? instituteDeepDiveData.parent_alliance.is_pxl_recomd_partnering : '-'}</div>
                  </div>
                  <div className="institution-dashboard-ques-wrap-card-container-content">
                    <div className="key-wrap-title">Who is recommending for partnering</div>
                    <div className="value-wrap-desc">{instituteDeepDiveData.parent_alliance.recomd_by_for_partnering ? instituteDeepDiveData.parent_alliance.recomd_by_for_partnering : '-'}</div>
                  </div>
                  <div className="institution-dashboard-ques-wrap-card-container-content">
                    <div className="key-wrap-title">Recommendation comments</div>
                    <div className="value-wrap-desc">{instituteDeepDiveData.parent_alliance.recommendation_comments ? instituteDeepDiveData.parent_alliance.recommendation_comments : '-'}</div>
                  </div>
                </>
              )}

          </div>
        </div>
      </div>
      <div className="alliance-key-contact-header">
        <div className="alliance-key-title">Key Contacts:</div>
        <Table
          columns={columns}
          dataSource={instituteDeepDiveData.other_personnel}
          pagination={false}
        />
      </div>
    </div>
  )
}
