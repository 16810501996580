import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_INSTITUTE_CENTERS_LIST = 'GET_INSTITUTE_CENTERS_LIST';
const GET_INSTITUTE_CENTERS_MORE = 'GET_INSTITUTE_CENTERS_MORE';
const GET_INSTITUTE_CENTERS_LIST_SUCCESS = 'GET_INSTITUTE_CENTERS_LIST_SUCCESS';
const GET_INSTITUTE_CENTERS_MORE_SUCCESS = 'GET_INSTITUTE_CENTERS_MORE_SUCCESS';
const GET_INSTITUTE_CENTERS_LIST_FAILURE = 'GET_INSTITUTE_CENTERS_LIST_FAILURE';
const GET_INSTITUTE_CENTERS_LIST_RESET = 'GET_INSTITUTE_CENTERS_LIST_RESET';

export const institutionConstitutentCentersListAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_INSTITUTE_CENTERS_LIST,
      url: `${process.env.apiUrl}v0/institution/constituteCenters`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
  })
}
export const institutionConstitutentCentersDataMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_INSTITUTE_CENTERS_MORE,
    url: `${process.env.apiUrl}v0/institution/constituteCenters`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const instituteDeepdiveResetAction = () => ({
  type: GET_INSTITUTE_CENTERS_LIST_RESET,
})

export function institutionConstitutentCentersListReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_INSTITUTE_CENTERS_LIST: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_INSTITUTE_CENTERS_LIST_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_INSTITUTE_CENTERS_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
        // loadMore: true,
      }
    }
    case GET_INSTITUTE_CENTERS_LIST_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_INSTITUTE_CENTERS_LIST_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}
