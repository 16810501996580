import {
  map, catchError, mergeMap, takeUntil, debounceTime,
} from 'rxjs/operators';
import { stringify } from 'query-string';
import { ofType } from 'redux-observable';
import { ajax } from 'rxjs/ajax';
import { of } from 'rxjs';
import { apiCall } from '../../../utils';

export const GENERIC_ACTION = 'GENERIC_ACTION';

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_DOCPROFILE_VALUES = 'GET_DOCPROFILE_VALUES';
const GET_DOCPROFILE_VALUES_CANCEL = 'GET_DOCPROFILE_VALUES_CANCEL';
const GET_DOCPROFILE_VALUES_SUCCESS = 'GET_DOCPROFILE_VALUES_SUCCESS';
const GET_DOCPROFILE_VALUES_FAILURE = 'GET_DOCPROFILE_VALUES_FAILURE';

// Auto Complete cancel action
export const getDocProfileValuesCancelAction = () => ({
  type: GET_DOCPROFILE_VALUES_CANCEL,
});

// Auto Complete action
export const getDocProfileValuesAction = (payload) => ({
  type: GET_DOCPROFILE_VALUES,
  payload,
});

// Auto Complete Success action
const getDocProfileValuesSuccess = (payload) => ({
  type: GET_DOCPROFILE_VALUES_SUCCESS,
  payload,
});

export const getDocProfileValuesEpic = (actions$) => actions$.pipe(
  ofType(GET_DOCPROFILE_VALUES),
  debounceTime(500),
  mergeMap((action) => ajax(
    apiCall(
      `${process.env.apiUrl}v0/search/deepdive/details?${stringify(action.payload)}`,
      'get',
    ),
  ).pipe(
    map((res) => getDocProfileValuesSuccess(res)),
    takeUntil(actions$.pipe(ofType(GET_DOCPROFILE_VALUES_CANCEL))),
    catchError((err) => of({
      type: GET_DOCPROFILE_VALUES_FAILURE,
      payload: err,
    })),
  )),
);

export function getDocProfileValuesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_DOCPROFILE_VALUES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      };
    }
    case GET_DOCPROFILE_VALUES_SUCCESS: {
      return {
        data: action.payload.response.data,
        loading: false,
        flag: true,
        error: false,
      };
    }
    case GET_DOCPROFILE_VALUES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      };
    }
    default:
      return state;
  }
}
