import React, { useEffect, useRef } from 'react'
import {
  Button, Radio, Space, Tabs, Form, Input, Select, DatePicker,
} from 'antd';
import './facilities.scss'
import moment from 'moment';
import { useSelector } from 'react-redux';
import { getCookie } from '../../../utils';

export default function Facilities({
  data, activekey, setActivekey, category,
}) {
  const siteQuesResponse = useSelector((store) => store.siteQuesResponse);
  const { Option } = Select;
  const myRef = useRef();

  const { TabPane } = Tabs;
  const onChange = (key) => {
    setActivekey(parseInt(key, 10))
  };

  useEffect(() => {
    if (document.querySelector('body > div:nth-child(32) > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div > div.steps-content > div > div > div > div.ant-tabs-content-holder')) {
      (() => document?.querySelector('body > div:nth-child(32) > div > div.ant-modal-wrap > div > div.ant-modal-content > div.ant-modal-body > div > div > div.steps-content > div > div > div > div.ant-tabs-content-holder').scrollTo(0, 0))()
    }
  }, [activekey])

  const onDateChange = (date, dateString, keyName) => {
    data.setCategoryData((oldState) => {
      const temp = {};
      temp[keyName] = dateString;
      return { ...oldState, ...temp };
    });
  };

  const getValue = (keyName) => {
    return data.categoryData[keyName];
  };

  const onCenterStateChange = (e, keyName) => {
    const { value } = e.target;
    data.setCategoryData((oldState) => {
      const temp = {};
      temp[keyName] = value;
      return { ...oldState, ...temp };
    });
  };

  const onCenterStateChangeForSelect = (e, keyName) => {
    data.setCategoryData((oldState) => {
      const temp = {};
      temp[keyName] = e;
      return { ...oldState, ...temp };
    });
  };

  const showOptions = (itm, key) => {
    const { options } = itm
    if (options.length) {
      return options.map((opt) => {
        if (getValue(itm.id) === opt.option_text) {
          const childQue = opt.child_questions
          return childQue?.map((child) => {
            const itemChild = Object.keys(data.questionsData)
              .map((res) => data.questionsData[res]).find((o) => o.id === child)
            return renderData(itemChild, key)
          })
        }
        return ''
      })
    }
    return ''
  }

  const showLabelOptions = (itm, key) => {
    const { child_queston } = itm
    if (child_queston.length) {
      return child_queston?.map((child) => {
        const itemChild = Object.keys(data.questionsData)
          .map((res) => data.questionsData[res]).find((o) => o.id === child)
        return renderData(itemChild, key)
      })
    }
    return ''
  }

  const convertDate = (getId) => {
    if (getValue(getId)) {
      const visitDate = moment(getValue(getId)).format('YYYY-MM-DD')
      return moment(visitDate, 'YYYY-MM-DD')
    }
    return undefined
  }

  const dateFormat = 'YYYY-MM-DD'

  const renderData = (itm, key) => {
    return (
      <Form layout="vertical" className={getCookie('permissions').includes('super_admin') && data.flag === 'admin' ? 'disable-pi-form' : null}>
        <div className="head-wrapper" ref={myRef}>
          <div className="inner-card">

            <div className="question-card">
              {/* <div>{idx + 1}.</div> */}
              <div>
                <Form.Item
                  label={itm.question_text}
                  className="question-wrapper"
                >
                  {itm.type === 'single_select' ? (
                    <>
                      <Radio.Group onChange={(e) => onCenterStateChange(e, itm.id)} value={getValue(itm.id)}>
                        <Space direction="vertical">
                          {itm.options.map((j) => {
                            return <Radio value={j.option_text}>{j.option_text}</Radio>
                          })}
                        </Space>
                      </Radio.Group>
                      {showOptions(itm, key)}
                    </>
                  ) : itm.type === 'text' ? (
                    <Input
                      className="input-wrapper"
                      placeholder="Please enter"
                      value={getValue(itm.id)}
                      onChange={(e) => onCenterStateChange(e, itm.id)}
                    />
                  ) : itm.type === 'file_upload' ? (
                    <Button type="primary" disabled>Upload here</Button>
                  ) : itm.type === 'multi_select' ? (
                    <Select
                      mode="multiple"
                      allowClear
                      style={{
                        width: '100%',
                      }}
                      placeholder="Please select"
                      value={getValue(itm.id)}
                      onChange={(e) => onCenterStateChangeForSelect(e, itm.id)}
                    >
                      {itm.options.map((itmm) => {
                        return <Option key={itmm.option_text}>{itmm.option_text}</Option>;
                      })}
                    </Select>
                  ) : itm.type === 'date' ? (
                    <Space direction="vertical">
                      <DatePicker
                        onChange={(date, dateString) => onDateChange(date, dateString, itm.id)}
                        value={convertDate(itm.id)}
                        allowClear={false}
                        format={dateFormat}
                        placeholder={dateFormat}
                      />
                    </Space>
                  ) : itm.type === 'label' ? showLabelOptions(itm, key) : ''}
                </Form.Item>
              </div>
            </div>
          </div>
        </div>
      </Form>
    )
  }

  const subCategories = [
    ...new Set(
      data.questionsData
        ?.filter((itm) => itm.child_category === category && itm.parent_id === 0)
        .map((itm) => itm.child_sub_category),
    ),
  ];

  return (
    <div className={getCookie('permissions').includes('super_admin') && data.flag === 'admin' ? 'investigator-page-wrapper-in-tab facilities-tab-sec disable-pi' : 'investigator-page-wrapper-in-tab facilities-tab-sec'}>
      <Tabs activeKey={`${activekey}`} onChange={onChange}>
        {subCategories.map((subCategory, index) => {
          return (
            <TabPane tab={subCategory} key={index}>
              <div className="tabs-content">
                <div className="tabs-content-row">
                  {data.questionsData?.filter((itm) => (itm.child_category === category && itm.child_sub_category === subCategory && itm.parent_id === 0))
                    .sort((a, b) => a.sr_no - b.sr_no)
                    .map((itm) => {
                      const childQues = itm.child_queston;
                      if (childQues.length) {
                        childQues.map((child) => {
                          return renderData(
                            siteQuesResponse?.data?.data?.find((i) => i.id === child),
                            subCategory,
                          )
                        })
                      }
                      return renderData(itm, subCategory);
                    })}
                </div>
              </div>
            </TabPane>
          )
        })}
      </Tabs>
    </div>
  )
}
