import { GENERIC_ACTION } from '../SearchResults/logic'

const ADD_PI = 'ADD_PI'
const ADD_PI_SUCCESS = 'ADD_PI_SUCCESS'
const ADD_PI_RESET = 'ADD_PI_RESET'
const ADD_PI_FAILURE = 'ADD_PI_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const addPiAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: ADD_PI,
      url: `${process.env.apiUrl}v0/search/addpi`,
      method: 'POST',
      auth: true,
      log: false, // for console
      ...meta,
    },
    showNotification: true, // show notification on success
    message: 'Investigator added successfully',
    hideNotification: false, // hide notification on error
    customErrMessage: 'PI already exists in the project results',
  })
}

export const addPiResetAction = () => ({
  type: ADD_PI_RESET,
})

export function addPiReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ADD_PI: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case ADD_PI_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case ADD_PI_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case ADD_PI_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
        status: action.payload.status,
      }
    }
    default:
      return state
  }
}
