import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_COUNTRY_DATA = 'GET_COUNTRY_DATA'
const GET_COUNTRY_DATA_SUCCESS = 'GET_COUNTRY_DATA_SUCCESS'
const GET_COUNTRY_DATA_FAILURE = 'GET_COUNTRY_DATA_FAILURE'
const GET_COUNTRY_DATA_RESET = 'GET_COUNTRY_DATA_RESET'

const GET_STATE_DATA = 'GET_STATE_DATA'
const GET_STATE_DATA_SUCCESS = 'GET_STATE_DATA_SUCCESS'
const GET_STATE_DATA_FAILURE = 'GET_STATE_DATA_FAILURE'
const GET_STATE_DATA_RESET = 'GET_STATE_DATA_RESET'

const GET_CENTER_TYPE_DATA = 'GET_CENTER_TYPE_DATA'
const GET_CENTER_TYPE_DATA_SUCCESS = 'GET_CENTER_TYPE_DATA_SUCCESS'
const GET_CENTER_TYPE_DATA_FAILURE = 'GET_CENTER_TYPE_DATA_FAILURE'
const GET_CENTER_TYPE_DATA_RESET = 'GET_CENTER_TYPE_DATA_RESET'

const GET_CHILD_CENTER_DATA = 'GET_CHILD_CENTER_DATA'
const GET_CHILD_CENTER_DATA_SUCCESS = 'GET_CHILD_CENTER_DATA_SUCCESS'
const GET_CHILD_CENTER_DATA_FAILURE = 'GET_CHILD_CENTER_DATA_FAILURE'
const GET_CHILD_CENTER_DATA_RESET = 'GET_CHILD_CENTER_DATA_RESET'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getCenterTypeDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_CENTER_TYPE_DATA,
      url: `${process.env.apiUrl}v0/search/getCentersFilters`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getCenterTypeDataResetAction = () => ({
  type: GET_CENTER_TYPE_DATA_RESET,
})

export function getCenterTypeDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_CENTER_TYPE_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_CENTER_TYPE_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_CENTER_TYPE_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_CENTER_TYPE_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getCountryDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_COUNTRY_DATA,
      url: `${process.env.apiUrl}v0/search/getCountryAndStateList`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getCountryDataResetAction = () => ({
  type: GET_COUNTRY_DATA_RESET,
})

export function getCountryDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_COUNTRY_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COUNTRY_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_COUNTRY_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_COUNTRY_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getStateDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_STATE_DATA,
      url: `${process.env.apiUrl}v0/search/getCountryAndStateList`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getStateDataResetAction = () => ({
  type: GET_STATE_DATA_RESET,
})

export function getStateDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_STATE_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_STATE_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_STATE_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_STATE_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getChildCenterDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_CHILD_CENTER_DATA,
      url: `${process.env.apiUrl}v0/institution/constituteCenters`,
      method: 'GET',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getChildCenterDataResetAction = () => ({
  type: GET_CHILD_CENTER_DATA_RESET,
})

export function getChildCenterDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_CHILD_CENTER_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_CHILD_CENTER_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_CHILD_CENTER_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_CHILD_CENTER_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
