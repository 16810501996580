import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
};

const GET_INSTITUTE_METRICS_DEEPDIVE_VALUES = 'GET_INSTITUTE_METRICS_DEEPDIVE_VALUES';
const GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_SUCCESS = 'GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_SUCCESS';
const GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_FAILURE = 'GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_FAILURE';
const GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_RESET = 'GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_RESET';

export const getInstituteMetricsDeepdiveListAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_INSTITUTE_METRICS_DEEPDIVE_VALUES,
      url: `${process.env.apiUrl}v0/institution/institutionMetrics`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export const instituteMetricsDeepdiveResetAction = () => ({
  type: GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_RESET,
})

export function instituteMetricsDeepdiveListReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_INSTITUTE_METRICS_DEEPDIVE_VALUES: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    case GET_INSTITUTE_METRICS_DEEPDIVE_VALUES_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    default:
      return state
  }
}
