import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_STUDIES_DATA = 'GET_STUDIES_DATA'
const GET_STUDIES_DATA_MORE = 'GET_STUDIES_DATA_MORE'
const GET_STUDIES_DATA_MORE_SUCCESS = 'GET_STUDIES_DATA_MORE_SUCCESS'
const GET_STUDIES_DATA_SUCCESS = 'GET_STUDIES_DATA_SUCCESS'
const GET_STUDIES_DATA_FAILURE = 'GET_STUDIES_DATA_FAILURE'
const GET_STUDIES_DATA_RESET = 'GET_STUDIES_DATA_RESET'

const GET_PARENT_CENTER_STUDIES_DATA = 'GET_PARENT_CENTER_STUDIES_DATA'
const GET_PARENT_CENTER_STUDIES_DATA_MORE = 'GET_PARENT_CENTER_STUDIES_DATA_MORE'
const GET_PARENT_CENTER_STUDIES_DATA_MORE_SUCCESS = 'GET_PARENT_CENTER_STUDIES_DATA_MORE_SUCCESS'
const GET_PARENT_CENTER_STUDIES_DATA_SUCCESS = 'GET_PARENT_CENTER_STUDIES_DATA_SUCCESS'
const GET_PARENT_CENTER_STUDIES_DATA_FAILURE = 'GET_PARENT_CENTER_STUDIES_DATA_FAILURE'
const GET_PARENT_CENTER_STUDIES_DATA_RESET = 'GET_PARENT_CENTER_STUDIES_DATA_RESET'

const GET_OTHER_STUDIES_DATA = 'GET_OTHER_STUDIES_DATA'
const GET_OTHER_STUDIES_DATA_MORE = 'GET_OTHER_STUDIES_DATA_MORE'
const GET_OTHER_STUDIES_DATA_MORE_SUCCESS = 'GET_OTHER_STUDIES_DATA_MORE_SUCCESS'
const GET_OTHER_STUDIES_DATA_SUCCESS = 'GET_OTHER_STUDIES_DATA_SUCCESS'
const GET_OTHER_STUDIES_DATA_FAILURE = 'GET_OTHER_STUDIES_DATA_FAILURE'
const GET_OTHER_STUDIES_DATA_RESET = 'GET_OTHER_STUDIES_DATA_RESET'

const GET_STUDIES_GRAPH = 'GET_STUDIES_GRAPH'
const GET_STUDIES_GRAPH_SUCCESS = 'GET_STUDIES_GRAPH_SUCCESS'
const GET_STUDIES_GRAPH_FAILURE = 'GET_STUDIES_GRAPH_FAILURE'
const GET_STUDIES_GRAPH_RESET = 'GET_STUDIES_GRAPH_RESET'

const GET_PARENT_STUDIES_GRAPH = 'GET_PARENT_STUDIES_GRAPH'
const GET_PARENT_STUDIES_GRAPH_SUCCESS = 'GET_PARENT_STUDIES_GRAPH_SUCCESS'
const GET_PARENT_STUDIES_GRAPH_FAILURE = 'GET_PARENT_STUDIES_GRAPH_FAILURE'
const GET_PARENT_STUDIES_GRAPH_RESET = 'GET_PARENT_STUDIES_GRAPH_RESET'

const GET_STUDIES_FILTERS = 'GET_STUDIES_FILTERS'
const GET_STUDIES_FILTERS_SUCCESS = 'GET_STUDIES_FILTERS_SUCCESS'
const GET_STUDIES_FILTERS_FAILURE = 'GET_STUDIES_FILTERS_FAILURE'
const GET_STUDIES_FILTERS_RESET = 'GET_STUDIES_FILTERS_RESET'

const GET_PARENT_STUDIES_FILTERS = 'GET_PARENT_STUDIES_FILTERS'
const GET_PARENT_STUDIES_FILTERS_SUCCESS = 'GET_PARENT_STUDIES_FILTERS_SUCCESS'
const GET_PARENT_STUDIES_FILTERS_FAILURE = 'GET_PARENT_STUDIES_FILTERS_FAILURE'
const GET_PARENT_STUDIES_FILTERS_RESET = 'GET_PARENT_STUDIES_FILTERS_RESET'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getOtherStudiesDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_OTHER_STUDIES_DATA,
      url: `${process.env.apiUrl}v0/search/getOtherTrialPaymentData`,
      method: 'get',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getOtherStudiesDataMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_OTHER_STUDIES_DATA_MORE,
    url: `${process.env.apiUrl}v0/search/getOtherTrialPaymentData`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getOtherStudiesDataResetAction = () => ({
  type: GET_OTHER_STUDIES_DATA_RESET,
})
export function getOtherStudiesDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_OTHER_STUDIES_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_OTHER_STUDIES_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_OTHER_STUDIES_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
          total_amount: action.payload.total_amount,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_OTHER_STUDIES_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_OTHER_STUDIES_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getStudiesDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_STUDIES_DATA,
      url: `${process.env.apiUrl}v0/search/getStudies`,
      method: 'get',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getStudiesDataMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_STUDIES_DATA_MORE,
    url: `${process.env.apiUrl}v0/search/getStudies`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getStudiesDataResetAction = () => ({
  type: GET_STUDIES_DATA_RESET,
})

export function getStudiesDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_STUDIES_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_STUDIES_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_STUDIES_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_STUDIES_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_STUDIES_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getParentStudiesGraphAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PARENT_STUDIES_GRAPH,
    url: `${process.env.apiUrl}v0/institution/institutionTrialsGraph`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getParentStudiesGraphResetAction = () => ({
  type: GET_PARENT_STUDIES_GRAPH_RESET,
})

export function getParentStudiesGraphReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PARENT_STUDIES_GRAPH: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_STUDIES_GRAPH_SUCCESS: {
      if (action.meta.graph_type) {
        return {
          data: {
            ...state.data, [action.meta.graph_type]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PARENT_STUDIES_GRAPH_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_STUDIES_GRAPH_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getStudiesGraphAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_STUDIES_GRAPH,
    url: `${process.env.apiUrl}v0/search/getStudiesGraph`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getStudiesGraphResetAction = () => ({
  type: GET_STUDIES_GRAPH_RESET,
})

export const getStudiesFiltersAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_STUDIES_FILTERS,
    url: `${process.env.apiUrl}v0/search/getStudiesGraph`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getStudiesFiltersResetAction = () => ({
  type: GET_STUDIES_FILTERS_RESET,
})

export function getStudiesFiltersReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_STUDIES_FILTERS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_STUDIES_FILTERS_SUCCESS: {
      if (action.meta.graph_type) {
        return {
          data: {
            ...state.data, [action.meta.graph_type]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_STUDIES_FILTERS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_STUDIES_FILTERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export function getStudiesGraphReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_STUDIES_GRAPH: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_STUDIES_GRAPH_SUCCESS: {
      if (action.meta.graph_type) {
        return {
          data: {
            ...state.data, [action.meta.graph_type]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_STUDIES_GRAPH_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_STUDIES_GRAPH_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getParentCenterStudiesDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_PARENT_CENTER_STUDIES_DATA,
      url: `${process.env.apiUrl}v0/institution/institutionTrialsData`,
      method: 'get',
      auth: true,
      // customHeaders: { 'Content-Type': undefined },
      log: false, // for console
      ...meta,
    },
    showNotification: false, // show notification on success
    hideNotification: false, // hide notification on error
  })
}

export const getParentCenterStudiesDataResetAction = () => ({
  type: GET_PARENT_CENTER_STUDIES_DATA_RESET,
})

export const getParentCenterStudiesDataMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PARENT_CENTER_STUDIES_DATA_MORE,
    url: `${process.env.apiUrl}v0/institution/institutionTrialsData`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getParentCenterStudiesDataReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PARENT_CENTER_STUDIES_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_CENTER_STUDIES_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PARENT_CENTER_STUDIES_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_PARENT_CENTER_STUDIES_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_PARENT_CENTER_STUDIES_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getParentStudiesFiltersAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_PARENT_STUDIES_FILTERS,
    url: `${process.env.apiUrl}v0/institution/institutionTrialsGraph`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getParentStudiesFiltersResetAction = () => ({
  type: GET_PARENT_STUDIES_FILTERS_RESET,
})

export function getParentStudiesFiltersReducer(state, action) {
  if (typeof state === 'undefined') {
    return INITIAL_STATE
  }
  switch (action.type) {
    case GET_PARENT_STUDIES_FILTERS: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_STUDIES_FILTERS_SUCCESS: {
      if (action.meta.graph_type) {
        return {
          data: {
            ...state.data, [action.meta.graph_type]: action.payload.data,
          },
          loading: false,
          flag: true,
          error: false,
        }
      }
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_PARENT_STUDIES_FILTERS_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_PARENT_STUDIES_FILTERS_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
