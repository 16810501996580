import { GENERIC_ACTION } from '../SearchResults/logic'

const INITIAL_STATE = {
  data: [],
  loading: false,
  error: false,
  flag: false,
}

const GET_CANCER_DATA = 'GET_CANCER_DATA'
const GET_CANCER_DATA_SUCCESS = 'GET_CANCER_DATA_SUCCESS'
const GET_CANCER_DATA_FAILURE = 'GET_CANCER_DATA_FAILURE'

export const getCancerDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_CANCER_DATA,
      url: `${process.env.apiUrl}v0/search/cancerTomorrow`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function getCancerDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_CANCER_DATA: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_CANCER_DATA_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_CANCER_DATA_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_COVID_DATA = 'GET_COVID_DATA'
const GET_COVID_DATA_SUCCESS = 'GET_COVID_DATA_SUCCESS'
const GET_COVID_DATA_FAILURE = 'GET_COVID_DATA_FAILURE'

export const getCovidDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_COVID_DATA,
      url: `${process.env.apiUrl}v0/search/ihmeCovidStats`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function getCovidDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COVID_DATA: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COVID_DATA_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COVID_DATA_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_COVID_COUNTRIES = 'GET_COVID_COUNTRIES'
const GET_COVID_COUNTRIES_SUCCESS = 'GET_COVID_COUNTRIES_SUCCESS'
const GET_COVID_COUNTRIES_FAILURE = 'GET_COVID_COUNTRIES_FAILURE'

export const getCovidCountriesAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_COVID_COUNTRIES,
      url: `${process.env.apiUrl}v0/search/countryProfileFilters`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function getCovidCountriesReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COVID_COUNTRIES: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COVID_COUNTRIES_SUCCESS: {
      // console.log('action.payload', action.payload.data)

      return {
        data: action.payload.data,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COVID_COUNTRIES_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_GBD_FILTER_DATA = 'GET_GBD_FILTER_DATA'
const GET_GBD_FILTER_DATA_SUCCESS = 'GET_GBD_FILTER_DATA_SUCCESS'
const GET_GBD_FILTER_DATA_RESET = 'GET_GBD_FILTER_DATA_RESET'
const GET_GBD_FILTER_DATA_FAILURE = 'GET_GBD_FILTER_DATA_FAILURE'

export const getGbdFilterDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_GBD_FILTER_DATA,
    url: `${process.env.apiUrl}v0/search/gbdForesightFilters`,
    method: 'GET',
    auth: true,
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getGbdFilterResetAction = () => ({
  type: GET_GBD_FILTER_DATA_RESET,
})

export function getGbdFilterReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_GBD_FILTER_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_GBD_FILTER_DATA_SUCCESS: {
      console.log('action===>', action)
      return {
        data: action.payload,
        loading: false,
        type: action.meta.type,
        flag: true,
        error: false,
      }
    }
    case GET_GBD_FILTER_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }
    case GET_GBD_FILTER_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

const GET_GBD_DATA = 'GET_GBD_DATA'
const GET_GBD_DATA_SUCCESS = 'GET_GBD_DATA_SUCCESS'
const GET_GBD_DATA_FAILURE = 'GET_GBD_DATA_FAILURE'

export const getGbdDataAction = (payload, meta = {}) => {
  return ({
    type: GENERIC_ACTION,
    payload,
    meta: {
      actionType: GET_GBD_DATA,
      url: `${process.env.apiUrl}v0/search/gbdForesightData`,
      method: 'GET',
      auth: true,
      log: false, // for console
      ...meta,
    },
    // showNotification: true, // show notification on success
    // message: 'PI added successfully',
    // hideNotification: false, // hide notification on error
  })
}

export function getGbdDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_GBD_DATA: {
      return {
        data: [],
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_GBD_DATA_SUCCESS: {
      console.log('action.payload', action.payload)

      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_GBD_DATA_FAILURE: {
      return {
        data: [],
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
