import React, { useEffect, useState } from 'react'
import {
  Button,
  Form, Input, Select,
}
from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getRoleForInviteAction, inviteUserAction } from './logic'
import Loader from '../../../common/components/Loader';

const { Option } = Select

const InviteUser = () => {
  const [formValue, setFormValue] = useState({
    firstName: '',
    lastName: '',
    email: '',
    role: [],
  })

  const [roleForInviteUser, setRoleForInviteUser] = useState([])

  const [adminDashboardForm] = Form.useForm();
  const dispatch = useDispatch()
  const getRoleForInviteResponse = useSelector((reduxState) => reduxState.getRoleForInviteResponse || {})
  const inviteUserResponse = useSelector((reduxState) => reduxState.inviteUserResponse || {})

  useEffect(() => {
    dispatch(getRoleForInviteAction())
  }, [])

  useEffect(() => {
    if (getRoleForInviteResponse.flag) {
      console.log('hello ji', getRoleForInviteResponse)
      if (getRoleForInviteResponse.data.data) {
        setRoleForInviteUser(getRoleForInviteResponse.data.data)
      }
    }
  }, [JSON.stringify(getRoleForInviteResponse)])

  const formValueChange = (e, keyName) => {
    if (keyName === 'firstName') {
      setFormValue({
        ...formValue,
        firstName: e.target.value,
      })
    }
    if (keyName === 'lastName') {
      setFormValue({
        ...formValue,
        lastName: e.target.value,
      })
    }
    if (keyName === 'email') {
      setFormValue({
        ...formValue,
        email: e.target.value,
      })
    }
    // setFormValue({
    //   ...formValue,
    //   keyName: e.target.value,
    // })
  }

  const handleRoleChange = (value) => {
    console.log('checl role', value)
    setFormValue({
      ...formValue,
      role: value,
    })
  }

  const handleClick = () => {
    console.log('check valye', formValue)
    const inviteUser = {
      email: formValue.email,
      roles: formValue.role.toString(),
      first_name: formValue.firstName,
      last_name: formValue.lastName,
    }
    dispatch(inviteUserAction({
      body: {
        inviteUser,
      },
    }))
  }

  useEffect(() => {
    if (inviteUserResponse.flag) {
      handleReset()
    }
  }, [JSON.stringify(inviteUserResponse)])

  const handleReset = () => {
    setFormValue({
      firstName: '',
      lastName: '',
      email: '',
      role: [],
    })
    adminDashboardForm.resetFields()
  }
  return (
    <div className="admin-dashboard">
      <Loader loading={inviteUserResponse.loading}>
        <Form
          layout="vertical"
          name="adminForm"
          form={adminDashboardForm}
        >
          <div className="input-content">
            <div className="name-card">
              <Form.Item
                label="First Name"
                name="firstName"
                rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 3, message: 'Minimum 3 characters' }]}
                className="name-wrapper"
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter first name"
                  value={formValue.firstName}
                  defaultValue={formValue.firstName}
                  onChange={(e) => formValueChange(e, 'firstName')}
                />

              </Form.Item>
            </div>

            <div className="name-card">
              <Form.Item
                label="Last Name"
                name="lastname"
                rules={[{ required: true, message: 'This is a required field' }, { type: 'string', min: 2, message: 'Minimum 3 characters' }]}
                className="name-wrapper"
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter last name"
                  value={formValue.lastName}
                  defaultValue={formValue.lastName}
                  onChange={(e) => formValueChange(e, 'lastName')}
                />

              </Form.Item>
            </div>
            <div className="Email-card">
              <Form.Item
                label="Email"
                name="email"
                rules={[{ required: true, message: 'This is a required field' }, { pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i, message: 'Enter a valid email' }]}
                className="email-wrapper"
              >
                <Input
                  className="input-wrapper"
                  placeholder="Enter email"
                  value={formValue.email}
                  defaultValue={formValue.email}
                  onChange={(e) => formValueChange(e, 'email')}
                />
              </Form.Item>
            </div>
            <div className="role-card">
              <Form.Item
                label="Role"
                className="role-wrapper"
                name="role"
                rules={[{ required: true, message: 'This is a required field' }]}
              >
                <Select
                  mode="multiple"
                  placeholder="Select Role"
                  style={{
                    width: 120,
                  }}
                  onChange={(e) => handleRoleChange(e)}
                  value={formValue.role}
                >
                  {/* <Option value="abc"> abc </Option>
                <Option value="abc1"> abc1 </Option>
                <Option value="abc2"> abc2 </Option>
                <Option value="abc3"> abc3 </Option> */}
                  {roleForInviteUser && roleForInviteUser.map((itm) => {
                    return <Option value={itm.role_id}>{itm.role_name}</Option>
                  })}
                </Select>
              </Form.Item>
            </div>
          </div>
          <div className="footer-btn">
            <Button className="save-btn" onClick={handleClick} disabled={formValue.firstName === '' || formValue.lastName === '' || formValue.email === '' || formValue?.role?.length === 0}> Invite</Button>
            <Button className="reset-btn" onClick={handleReset}> Reset
            </Button>
          </div>
        </Form>
      </Loader>
    </div>
  )
}

export default InviteUser
