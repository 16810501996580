import React, { useEffect, useState } from 'react'
import './parent.scss'
import {
  Modal, Table, Tag, Tooltip,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Axios from 'axios';
import { parse, stringify } from 'query-string';
import StarRatings from 'react-star-ratings';
import { getInstituteMetricsDeepdiveListAction } from './logic';
import Loader from '../../../common/components/Loader';
import BarChart from '../../../common/components/BarChart';
import { getCookie, getFormattedDate } from '../../../utils';

export default function InstitutionMetrics({ allianceType, dateFilter }) {
  const instituteDeepdiveList = useSelector((store) => store.instituteMetricsDeepdiveList);
  const { id } = parse(location.search)
  const [instituteDeepDiveData, setInstituteDeepDiveData] = useState([]);
  const dispatch = useDispatch();
  const [phaseVisible, setphaseVisible] = useState(false);
  const [indicationVisible, setindicationVisible] = useState(false);
  const [ageRangeVisible, setAgeRangeVisible] = useState(false);
  const [medianSite, setMedianSite] = useState(false)
  const [sponsorVisible, setSponsorVisible] = useState(false);
  const [studyPerTAVisible, setStudyPerTAVisible] = useState(false);
  const [activeStudies, setActiveStudies] = useState(false);
  const [sFGPerTA, setSFGPerTA] = useState(false)
  const [rFGPerTA, setRFGPerTA] = useState(false)
  const [isConfirmationModal, setIsConfirmationModal] = useState(false)
  const [activeToggle, setActiveToggle] = useState('graph')
  const [modalActiveToggle, setModalActiveToggle] = useState('modalGraph')

  const showPhaseModal = () => {
    setphaseVisible(true);
  };
  const handlePhaseCancel = (e) => {
    e.stopPropagation();
    setphaseVisible(false);
    setModalActiveToggle('modalGraph')
  };
  useEffect(() => {
    dispatch(getInstituteMetricsDeepdiveListAction({
      id,
      alliance_type: allianceType,
      filters: JSON.stringify({
        date: dateFilter,
      }),
    }))
  }, [allianceType, dateFilter]);

  useEffect(() => {
    if (instituteDeepdiveList.flag) {
      setInstituteDeepDiveData(instituteDeepdiveList?.data)
    }
  }, [JSON.stringify(instituteDeepdiveList)])

  const tags = [
    { name: '# of enrolling sites', size: instituteDeepDiveData?.enrolling_sites_count },
    { name: '# of non-enrolling sites', size: instituteDeepDiveData?.non_enrolling_sites_count ? instituteDeepDiveData.non_enrolling_sites_count : '-' },
    { name: '# of PIs', size: instituteDeepDiveData?.no_of_pis ? instituteDeepDiveData.no_of_pis : '-' },
    { name: '# of child centers', size: instituteDeepDiveData?.no_of_centers },
    { name: '# of Subject Entered Treatment', size: instituteDeepDiveData?.subjects_entered_treatment },
    { name: '# of Subject Planned Entered Treatment', size: instituteDeepDiveData?.subjects_planned_entered_treatment },
    { name: 'Sum of patient enrolled', size: instituteDeepDiveData?.sum_enrollment_subjects },
  ];
  const showGraph = (chartData) => {
    return (
      chartData
      && !chartData?.every((item) => {
        return item.value === 0;
      })
    );
  };

  const showIndicationModal = () => {
    setindicationVisible(true);
  };
  const handleIndicationCancel = (e) => {
    e.stopPropagation();
    setindicationVisible(false);
  };

  const showAgeRangeModal = () => {
    setAgeRangeVisible(true);
  };

  const setMedianSiteModal = () => {
    setMedianSite(true);
  };

  const handleMedianCancel = (e) => {
    e.stopPropagation();
    setMedianSite(false);
  };

  const handleAgeRangeCancel = (e) => {
    e.stopPropagation();
    setAgeRangeVisible(false);
  };

  const showSponsorModal = () => {
    setSponsorVisible(true);
  };
  const handleSponsorCancel = (e) => {
    e.stopPropagation();
    setSponsorVisible(false);
  };

  const showStudyPerTAModal = () => {
    setStudyPerTAVisible(true);
  }
  const handleStudyPerTACancel = (e) => {
    e.stopPropagation();
    setStudyPerTAVisible(false);
  };

  const setActiveStudiesModal = () => {
    setActiveStudies(true);
  }
  const handleActiveStudiesCancel = (e) => {
    e.stopPropagation();
    setActiveStudies(false);
  };

  const setRFGPerTAModal = () => {
    setRFGPerTA(true);
  }
  const handleRFGPerTACancel = (e) => {
    e.stopPropagation();
    setRFGPerTA(false);
  };

  const setSFGPerTAModal = () => {
    setSFGPerTA(true);
  }
  const handleSFGPerTACancel = (e) => {
    e.stopPropagation();
    setSFGPerTA(false);
  };

  const getStars = (value) => {
    if (value === 'Class 3') {
      return 1
    }
    if (value === 'Class 2') {
      return 2
    }
    if (value === 'Class 1') {
      return 3
    }
    return 0
  }

  const RenderCardDetails = () => {
    return (
      <div className="content deepdive-content">
        <div className="institution-metrics-tab">
          <div className="left-container institution-metrics-tab-tp">
            <div className="content-wrap">
              <div className="key-wrap">Site Start-up Period: </div>
              <div className="value-wrap">{instituteDeepDiveData?.site_startup_period ? instituteDeepDiveData?.site_startup_period.toFixed(2) : '-'}</div>
            </div>
            <div className="content-wrap">
              <div className="key-wrap">SC(Study Country) start-up Factor: </div>
              <div className="value-wrap">{instituteDeepDiveData?.sc_startup_factor ? instituteDeepDiveData?.sc_startup_factor.toFixed(2) : '-'}</div>
            </div>
            <Tooltip title={instituteDeepDiveData.reliability_score ? `Reliability score : ${instituteDeepDiveData.reliability_score.toFixed(2)}` : '-'}>
              <div className="content-wrap rating-sec-wrap">
                <div className="key-wrap">Reliability: </div>
                <div className="value-wrap">{instituteDeepDiveData?.reliability_class
                  ? (
                    <StarRatings
                      rating={getStars(instituteDeepDiveData?.reliability_class)}
                      starRatedColor="#8A0050"
                      numberOfStars={3}
                      starDimension="16px"
                      starSpacing="1px"
                      starEmptyColor="#D099B9"
                    />
                  ) : '-'}
                </div>
              </div>
            </Tooltip>
          </div>
          <div className="tag-wrapper">
            {instituteDeepdiveList.flag && tags.map((itm, idx) => {
              return (
                <Tag key={idx}>
                  <div className="details-abt-tags">
                    <div className="details-abt-tags-title">{itm.name}:&nbsp; </div>
                    <div className="details-abt-tags-value">{itm.size}</div>
                  </div>
                </Tag>
              )
            })}
          </div>
        </div>
      </div>
    )
  }

  const sponsorColumns = [
    {
      title: 'Sponsors',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Studies by Sponsors',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const phaseColumns = [
    {
      title: 'Phase',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Phase vs Studies',
      dataIndex: 'value',
      key: 'value',
    },
  ];

  const IndicationVsStudiesColumns = [
    {
      title: 'Indication',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Indication vs Studies',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  const ageRangeColumns = [
    {
      title: 'Age range',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: '# Studies by Age range',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  const studiesPerTAColumns = [
    {
      title: 'TA Name',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Studies per TA',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  const MedianColumns = [
    {
      title: 'Site',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Period',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  const activeStudyColumn = [
    {
      title: 'Status',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'Study',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  const sfPerTaColumns = [
    {
      title: 'Startup Factor',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'TA',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  const rfPerTaColumns = [
    {
      title: 'Startup Factor',
      dataIndex: 'key',
      key: 'key',
    },
    {
      title: 'TA',
      dataIndex: 'value',
      key: 'value',
    },
  ]

  const downloadToCsv = (type) => {
    setIsConfirmationModal(true)
    const date = new Date()
    let params = {}
    const filename = `${type}_records_${getFormattedDate(date)}_${date.getHours()}-${date.getMinutes()}-${date.getSeconds()}.xlsx`
    if (type === 'studies_by_Sponsor') {
      params = {
        id: parse(location.search).id,
        alliance_type: allianceType,
        filters: JSON.stringify({
          sponsor: [],
          date: dateFilter,
        }),
      }
    }
    if (type === 'studies_per_phase') {
      params = {
        id: parse(location.search).id,
        alliance_type: allianceType,
        filters: JSON.stringify({
          phase: [],
          date: dateFilter,
        }),
      }
    }
    if (type === 'indication_vs_studies') {
      params = {
        id: parse(location.search).id,
        alliance_type: allianceType,
        filters: JSON.stringify({
          indication: [],
          date: dateFilter,
        }),
      }
    }
    if (type === 'studies_by_age_range') {
      params = {
        id: parse(location.search).id,
        alliance_type: allianceType,
        filters: JSON.stringify({
          age_range: [],
          date: dateFilter,
        }),
      }
    }
    if (type === 'studies_per_ta') {
      params = {
        id: parse(location.search).id,
        alliance_type: allianceType,
        filters: JSON.stringify({
          therapeutic_area: [],
          date: dateFilter,
        }),
      }
    }
    if (type === 'active_studies_per_study_status') {
      params = {
        id: parse(location.search).id,
        alliance_type: allianceType,
        filters: JSON.stringify({
          status: [],
          date: dateFilter,
        }),
      }
    }

    Axios({
      url: `${process.env.apiUrl}/v0/export/exportInstitutionTrialsData?${stringify(params)}`,
      method: 'GET',
      responseType: 'blob', // important
      headers: {
        Authorization: getCookie('accessToken'),
        // 'Content-Disposition': `attachment;filename=Data.${
        //   filetype === 'CSV' ? 'csv' : 'xlsx'
        // }`,
        'Content-type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'Access-Control-Allow-Origin': `${process.env.applicationUrl}`,
      },
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click();
        return null;
      })
      .catch((e) => {
        return e;
      })
  }
  return (
    <div className="CardList institution-dashboard">
      <Loader loading={instituteDeepdiveList.loading} error={instituteDeepdiveList.error}>
        <div className="search-result-list">
          <div className="patientcard">
            {RenderCardDetails()}
          </div>

          {activeToggle === 'graph' && (
            <div className="graph-section-results">
              <div className="graph-section-results-toggle-header">
                <div className="toggle-switch-wrap">
                  <div
                    className={`toggle-switch-itm ${activeToggle === 'graph' ? 'active' : ''}`}
                    role="presentation"
                    onClick={() => {
                      setActiveToggle('graph');
                    }}
                  >
                    Graph View
                  </div>
                  <div
                    className={`toggle-switch-itm ${activeToggle === 'count' ? 'active' : ''}`}
                    role="presentation"
                    onClick={() => { setActiveToggle('count'); }}
                  >
                    Counts
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="graph-row">

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Studies by Sponsor</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Export data">
                          <div
                            className="export-btn-small download-btn graph-head-icon"
                            onClick={() => downloadToCsv('studies_by_Sponsor')}
                            role="presentation"
                          />
                        </Tooltip>
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showSponsorModal}
                            role="presentation"
                          />
                        </Tooltip>

                        <Modal
                          title="Studies by Sponsor"
                          open={sponsorVisible}
                          onCancel={handleSponsorCancel}
                          width={1000}
                          footer={null}
                          destroyOnClose
                          className="insti-metrix-modal"
                        >
                          <Tooltip title="Export data">
                            <div
                              className="export-btn-large export-btn-modal graph-head-icon"
                              onClick={() => downloadToCsv('studies_by_Sponsor')}
                              role="presentation"
                            />
                          </Tooltip>
                          <div className="toggle-switch-wrap">
                            <div
                              className={`toggle-switch-itm ${modalActiveToggle === 'modalGraph' ? 'active' : ''}`}
                              role="presentation"
                              onClick={() => {
                                setModalActiveToggle('modalGraph');
                              }}
                            >
                              Graph View
                            </div>
                            <div
                              className={`toggle-switch-itm ${modalActiveToggle === 'modalCount' ? 'active' : ''}`}
                              role="presentation"
                              onClick={() => { setModalActiveToggle('modalCount'); }}
                            >
                              Counts
                            </div>
                          </div>
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.studies_by_sponsor_graph) && modalActiveToggle === 'modalGraph' ? (
                              <>
                                <BarChart
                                  data={instituteDeepDiveData.studies_by_sponsor_graph}
                                  width={(instituteDeepDiveData.studies_by_sponsor_graph.length * 30) > 900 ? instituteDeepDiveData.studies_by_sponsor_graph.length * 30 : 900}
                                  height={400}
                                  top={15}
                                  bottom={200}
                                  left={30}
                                  right={200}
                                  studies="parentSponsor"
                                  checkDataset="centers"
                                  titleName="Sponsor"
                                  showModal
                                  dateFilter={dateFilter}
                                  allianceType={allianceType}
                                  setIsConfirmationModal={setIsConfirmationModal}
                                />
                              </>
                            ) : (showGraph(instituteDeepDiveData?.studies_by_age_range_graph) && modalActiveToggle === 'modalCount')
                              ? (<Table dataSource={instituteDeepDiveData.studies_by_age_range_graph} columns={ageRangeColumns} />) : (
                                <span className="no-data-inside no-data-modal">No data available</span>
                              )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_sponsor_graph) ? (
                        <>
                          <BarChart
                            data={instituteDeepDiveData?.studies_by_sponsor_graph?.slice(0, 4)}
                            width={350}
                            height={260}
                            top={15}
                            bottom={100}
                            left={30}
                            right={60}
                            studies="parentSponsor"
                            checkDataset="centers"
                            titleName="Sponsor"
                            showModal
                            dateFilter={dateFilter}
                            allianceType={allianceType}
                            setIsConfirmationModal={setIsConfirmationModal}
                          />
                        </>
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Studies per phase</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Export data">
                          <div
                            className="export-btn-small download-btn graph-head-icon"
                            onClick={() => downloadToCsv('studies_per_phase')}
                            role="presentation"
                          />
                        </Tooltip>
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showPhaseModal}
                            role="presentation"
                          >
                            <Modal
                              title="Phases vs Studies"
                              visible={phaseVisible}
                              onCancel={handlePhaseCancel}
                              width={1000}
                              footer={null}
                              className="insti-metrix-modal"
                            >
                              <Tooltip title="Export data">
                                <div
                                  className="export-btn-large export-btn-modal graph-head-icon"
                                  onClick={() => downloadToCsv('studies_per_phase')}
                                  role="presentation"
                                />
                              </Tooltip>
                              <div className="toggle-switch-wrap">
                                <div
                                  className={`toggle-switch-itm ${modalActiveToggle === 'modalGraph' ? 'active' : ''}`}
                                  role="presentation"
                                  onClick={() => {
                                    setModalActiveToggle('modalGraph');
                                  }}
                                >
                                  Graph View
                                </div>
                                <div
                                  className={`toggle-switch-itm ${modalActiveToggle === 'modalCount' ? 'active' : ''}`}
                                  role="presentation"
                                  onClick={() => { setModalActiveToggle('modalCount'); }}
                                >
                                  Counts
                                </div>
                              </div>
                              <div className="graph-view" />
                              <div className="graph-view barchart">
                                {(showGraph(instituteDeepDiveData?.studies_by_phase_graph) && modalActiveToggle === 'modalGraph') ? (
                                  <BarChart
                                    data={instituteDeepDiveData.studies_by_phase_graph?.slice(0, 50)}
                                    width={(instituteDeepDiveData.studies_by_phase_graph.length * 30) > 900 ? instituteDeepDiveData.studies_by_phase_graph.length * 30 : 900}
                                    height={280}
                                    top={15}
                                    bottom={90}
                                    left={30}
                                    right={0}
                                    studies="parentPhase"
                                    checkDataset="centers"
                                    titleName="Phase"
                                    showModal
                                    dateFilter={dateFilter}
                                    allianceType={allianceType}
                                    setIsConfirmationModal={setIsConfirmationModal}
                                  />
                                ) : (showGraph(instituteDeepDiveData?.studies_by_phase_graph) && modalActiveToggle === 'modalCount')
                                  ? (<Table dataSource={instituteDeepDiveData?.studies_by_phase_graph} columns={phaseColumns} />)
                                  : (
                                    <span className="no-data-inside no-data-modal">No data available</span>
                                  )}
                              </div>
                            </Modal>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_phase_graph) ? (
                        <BarChart
                          data={instituteDeepDiveData?.studies_by_phase_graph?.slice(0, 4)}
                          width={350}
                          height={260}
                          top={15}
                          bottom={90}
                          left={30}
                          right={0}
                          studies="parentPhase"
                          checkDataset="centers"
                          titleName="Phase"
                          showModal
                          dateFilter={dateFilter}
                          allianceType={allianceType}
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Indication vs Studies</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Export data">
                          <div
                            className="export-btn-small download-btn graph-head-icon"
                            onClick={() => downloadToCsv('indication_vs_studies')}
                            role="presentation"
                          />
                        </Tooltip>
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showIndicationModal}
                            role="presentation"
                          >
                            <Modal
                              title="Indication vs Studies"
                              visible={indicationVisible}
                              onCancel={handleIndicationCancel}
                              width={1000}
                              footer={null}
                              className="insti-metrix-modal"
                            >
                              <Tooltip title="Export data">
                                <div
                                  className="export-btn-large export-btn-modal graph-head-icon"
                                  onClick={() => downloadToCsv('indication_vs_studies')}
                                  role="presentation"
                                />
                              </Tooltip>
                              <div className="toggle-switch-wrap">
                                <div
                                  className={`toggle-switch-itm ${modalActiveToggle === 'modalGraph' ? 'active' : ''}`}
                                  role="presentation"
                                  onClick={() => {
                                    setModalActiveToggle('modalGraph');
                                  }}
                                >
                                  Graph View
                                </div>
                                <div
                                  className={`toggle-switch-itm ${modalActiveToggle === 'modalCount' ? 'active' : ''}`}
                                  role="presentation"
                                  onClick={() => { setModalActiveToggle('modalCount'); }}
                                >
                                  Counts
                                </div>
                              </div>
                              <div className="graph-view">
                                {showGraph(instituteDeepDiveData?.studies_by_indication_graph) && modalActiveToggle === 'modalGraph' ? (
                                  <BarChart
                                    data={instituteDeepDiveData.studies_by_indication_graph.slice(0, 50)}
                                    width={(instituteDeepDiveData.studies_by_indication_graph.length * 30) > 900 ? 1500 : 900}
                                    height={400}
                                    top={15}
                                    bottom={200}
                                    left={30}
                                    right={200}
                                    studies="parentIndication"
                                    checkDataset="centers"
                                    titleName="Indication"
                                    showModal
                                    dateFilter={dateFilter}
                                    allianceType={allianceType}
                                    setIsConfirmationModal={setIsConfirmationModal}
                                  />
                                ) : (showGraph(instituteDeepDiveData?.studies_by_indication_graph) && modalActiveToggle === 'modalCount')
                                  ? (<Table dataSource={instituteDeepDiveData.studies_by_indication_graph} columns={IndicationVsStudiesColumns} />) : (
                                    <span className="no-data-inside no-data-modal">No data available</span>
                                  )}
                              </div>
                            </Modal>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_indication_graph) ? (
                        <BarChart
                          data={instituteDeepDiveData.studies_by_indication_graph.slice(0, 4)}
                          width={350}
                          height={260}
                          top={15}
                          bottom={100}
                          left={30}
                          right={60}
                          studies="parentIndication"
                          checkDataset="centers"
                          titleName="Indication"
                          showModal
                          dateFilter={dateFilter}
                          allianceType={allianceType}
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title"># Studies by Age range</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Export data">
                          <div
                            className="export-btn-small download-btn graph-head-icon"
                            onClick={() => downloadToCsv('studies_by_age_range')}
                            role="presentation"
                          />
                        </Tooltip>
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showAgeRangeModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="# Studies by Age range"
                          open={ageRangeVisible}
                          onCancel={handleAgeRangeCancel}
                          width={1000}
                          footer={null}
                          className="insti-metrix-modal"
                        >
                          <Tooltip title="Export data">
                            <div
                              className="export-btn-large export-btn-modal graph-head-icon"
                              onClick={() => downloadToCsv('studies_by_age_range')}
                              role="presentation"
                            />
                          </Tooltip>
                          <div className="toggle-switch-wrap">
                            <div
                              className={`toggle-switch-itm ${modalActiveToggle === 'modalGraph' ? 'active' : ''}`}
                              role="presentation"
                              onClick={() => {
                                setModalActiveToggle('modalGraph');
                              }}
                            >
                              Graph View
                            </div>
                            <div
                              className={`toggle-switch-itm ${modalActiveToggle === 'modalCount' ? 'active' : ''}`}
                              role="presentation"
                              onClick={() => { setModalActiveToggle('modalCount'); }}
                            >
                              Counts
                            </div>
                          </div>
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.studies_by_age_range_graph) && modalActiveToggle === 'modalGraph' ? (
                              <BarChart
                                data={instituteDeepDiveData?.studies_by_age_range_graph.map((itm) => {
                                  return { ...itm, value: itm.study_count }
                                })}
                                width={(instituteDeepDiveData.studies_by_age_range_graph.length * 30) > 900 ? instituteDeepDiveData.studies_by_age_range_graph.length * 30 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="parentAgeRange"
                                checkDataset="centers"
                                titleName="Age Range"
                                showModal
                                dateFilter={dateFilter}
                                allianceType={allianceType}
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            ) : (showGraph(instituteDeepDiveData?.studies_by_age_range_graph) && modalActiveToggle === 'modalCount')
                              ? (<Table dataSource={instituteDeepDiveData.studies_by_age_range_graph} columns={ageRangeColumns} />) : (
                                <span className="no-data-inside no-data-modal">No data available</span>
                              )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_age_range_graph) ? (
                        <BarChart
                          data={instituteDeepDiveData?.studies_by_age_range_graph.map((itm) => {
                            return { ...itm, value: itm.study_count }
                          })}
                          width={350}
                          height={260}
                          top={15}
                          bottom={90}
                          left={40}
                          right={10}
                          studies="parentAgeRange"
                          checkDataset="centers"
                          titleName="Age Range"
                          showModal
                          dateFilter={dateFilter}
                          allianceType={allianceType}
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Studies per TA</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Export data">
                          <div
                            className="export-btn-small download-btn graph-head-icon"
                            onClick={() => downloadToCsv('studies_per_ta')}
                            role="presentation"
                          />
                        </Tooltip>
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showStudyPerTAModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Studies per TA"
                          open={studyPerTAVisible}
                          onCancel={handleStudyPerTACancel}
                          width={1000}
                          footer={null}
                          className="insti-metrix-modal"
                        >
                          <Tooltip title="Export data">
                            <div
                              className="export-btn-large export-btn-modal graph-head-icon"
                              onClick={() => downloadToCsv('studies_per_ta')}
                              role="presentation"
                            />
                          </Tooltip>
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.studies_by_TA_graph) ? (
                              <BarChart
                                data={instituteDeepDiveData?.studies_by_TA_graph.slice(0, 50)}
                                width={(instituteDeepDiveData.studies_by_TA_graph.length * 30) > 900 ? 1500 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="parentStudiesTA"
                                checkDataset="centers"
                                titleName="Therapeutic Area"
                                showModal
                                dateFilter={dateFilter}
                                allianceType={allianceType}
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            ) : (
                              <span className="no-data-inside no-data-modal">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_TA_graph) ? (
                        <BarChart
                          data={instituteDeepDiveData?.studies_by_TA_graph.slice(0, 4)}
                          width={350}
                          height={260}
                          top={15}
                          bottom={90}
                          left={40}
                          right={10}
                          studies="parentStudiesTA"
                          checkDataset="centers"
                          titleName="Therapeutic Area"
                          showModal
                          dateFilter={dateFilter}
                          allianceType={allianceType}
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Active studies per study status</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Export data">
                          <div
                            className="export-btn-small download-btn graph-head-icon"
                            onClick={() => downloadToCsv('active_studies_per_study_status')}
                            role="presentation"
                          />
                        </Tooltip>
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={setActiveStudiesModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Active studies per study status"
                          open={activeStudies}
                          onCancel={handleActiveStudiesCancel}
                          width={1000}
                          footer={null}
                          className="insti-metrix-modal"
                        >
                          <Tooltip title="Export data">
                            <div
                              className="export-btn-large export-btn-modal graph-head-icon"
                              onClick={() => downloadToCsv('active_studies_per_study_status')}
                              role="presentation"
                            />
                          </Tooltip>
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.studies_by_status_graph) ? (
                              <BarChart
                                data={instituteDeepDiveData?.studies_by_status_graph.slice(0, 50)}
                                width={(instituteDeepDiveData.studies_by_status_graph.length * 30) > 900 ? 1500 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="parentStatus"
                                checkDataset="centers"
                                titleName="Status"
                                showModal
                                dateFilter={dateFilter}
                                allianceType={allianceType}
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            ) : (
                              <span className="no-data-inside no-data-modal">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_status_graph) ? (
                        <BarChart
                          data={instituteDeepDiveData?.studies_by_status_graph.slice(0, 4)}
                          width={350}
                          height={260}
                          top={15}
                          bottom={90}
                          left={40}
                          right={10}
                          studies="parentStatus"
                          checkDataset="centers"
                          titleName="Status"
                          showModal
                          dateFilter={dateFilter}
                          allianceType={allianceType}
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Median site startup period</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={setMedianSiteModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Median site startup period"
                          open={medianSite}
                          onCancel={handleMedianCancel}
                          width={1000}
                          footer={null}
                          className="insti-metrix-modal"
                        >
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.median_site_startup_period) ? (
                              <BarChart
                                data={instituteDeepDiveData?.median_site_startup_period.slice(0, 50)}
                                width={(instituteDeepDiveData.median_site_startup_period.length * 30) > 900 ? 1500 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="medianSIte"
                                checkDataset="centers"
                                titleName="MedianSite"
                                dateFilter={dateFilter}
                                allianceType={allianceType}
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            ) : (
                              <span className="no-data-inside no-data-modal">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.median_site_startup_period) ? (
                        <BarChart
                          data={instituteDeepDiveData?.median_site_startup_period.slice(0, 4)}
                          width={350}
                          height={260}
                          top={15}
                          bottom={90}
                          left={40}
                          right={10}
                          studies="medianSIte"
                          checkDataset="centers"
                          titleName="medianSIte"
                          dateFilter={dateFilter}
                          allianceType={allianceType}
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Site SC Start-up Factor global and per TA</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={setSFGPerTAModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Site SC Start-up Factor global and per TA"
                          open={sFGPerTA}
                          onCancel={handleSFGPerTACancel}
                          width={1000}
                          footer={null}
                          className="insti-metrix-modal"
                        >
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.sc_startup_factor_per_ta) ? (
                              <BarChart
                                data={instituteDeepDiveData?.sc_startup_factor_per_ta.slice(0, 50)}
                                width={(instituteDeepDiveData.sc_startup_factor_per_ta.length * 30) > 900 ? 1500 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="medianSIte"
                                checkDataset="centers"
                                titleName="MedianSite"
                                dateFilter={dateFilter}
                                allianceType={allianceType}
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            ) : (
                              <span className="no-data-inside no-data-modal">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.sc_startup_factor_per_ta) ? (
                        <BarChart
                          data={instituteDeepDiveData?.sc_startup_factor_per_ta.slice(0, 4)}
                          width={350}
                          height={260}
                          top={15}
                          bottom={90}
                          left={40}
                          right={10}
                          studies="medianSIte"
                          checkDataset="centers"
                          titleName="medianSIte"
                          dateFilter={dateFilter}
                          allianceType={allianceType}
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Site SC Recruitment Factor global and per TA</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={setRFGPerTAModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Site SC Recruitment Factor global and per TA"
                          open={rFGPerTA}
                          onCancel={handleRFGPerTACancel}
                          width={1000}
                          footer={null}
                          className="insti-metrix-modal"
                        >
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.sc_recruitment_factor_per_ta) ? (
                              <BarChart
                                data={instituteDeepDiveData?.sc_recruitment_factor_per_ta.slice(0, 50)}
                                width={(instituteDeepDiveData.sc_recruitment_factor_per_ta.length * 30) > 900 ? 1500 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="medianSIte"
                                checkDataset="centers"
                                titleName="MedianSite"
                                dateFilter={dateFilter}
                                allianceType={allianceType}
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            ) : (
                              <span className="no-data-inside no-data-modal">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.sc_recruitment_factor_per_ta) ? (
                        <BarChart
                          data={instituteDeepDiveData?.sc_recruitment_factor_per_ta.slice(0, 4)}
                          width={350}
                          height={260}
                          top={15}
                          bottom={90}
                          left={40}
                          right={10}
                          studies="medianSIte"
                          checkDataset="centers"
                          titleName="medianSIte"
                          dateFilter={dateFilter}
                          allianceType={allianceType}
                          setIsConfirmationModal={setIsConfirmationModal}
                        />
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                </div>
              </div>
            </div>
          )}

          {activeToggle === 'count' && (
            <div className="graph-section-results">
              <div className="graph-section-results-toggle-header">
                <div className="toggle-switch-wrap">
                  <div
                    className={`toggle-switch-itm ${activeToggle === 'graph' ? 'active' : ''}`}
                    role="presentation"
                    onClick={() => {
                      setActiveToggle('graph');
                    }}
                  >
                    Graph View
                  </div>
                  <div
                    className={`toggle-switch-itm ${activeToggle === 'count' ? 'active' : ''}`}
                    role="presentation"
                    onClick={() => { setActiveToggle('count'); }}
                  >
                    Counts
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="graph-row">
                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Studies by Sponsor</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showPhaseModal}
                            role="presentation"
                          >
                            <Modal
                              title="Studies by Sponsor"
                              visible={phaseVisible}
                              onCancel={handlePhaseCancel}
                              width={1000}
                              footer={null}
                            >
                              <div className="graph-view">
                                {showGraph(instituteDeepDiveData?.studies_by_sponsor_graph) ? (
                                  <Table dataSource={instituteDeepDiveData.studies_by_sponsor_graph} columns={sponsorColumns} />

                                ) : (
                                  <span className="no-data-inside">No data available</span>
                                )}
                              </div>
                            </Modal>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      <Table dataSource={instituteDeepDiveData.studies_by_sponsor_graph.slice(0, 4)} columns={sponsorColumns} pagination={false} />
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Indication vs Studies</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showIndicationModal}
                            role="presentation"
                          >
                            <Modal
                              title="Indication vs Studies"
                              visible={indicationVisible}
                              onCancel={handleIndicationCancel}
                              width={1000}
                              footer={null}
                            >
                              <div className="graph-view">
                                {showGraph(instituteDeepDiveData?.studies_by_indication_graph) ? (
                                  <Table dataSource={instituteDeepDiveData.studies_by_indication_graph} columns={IndicationVsStudiesColumns} />

                                ) : (
                                  <span className="no-data-inside">No data available</span>
                                )}
                              </div>
                            </Modal>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_indication_graph) ? (
                        <Table dataSource={instituteDeepDiveData.studies_by_indication_graph.slice(0, 4)} columns={IndicationVsStudiesColumns} pagination={false} />

                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title"># Studies by Age range</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showAgeRangeModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="# Studies by Age range"
                          open={ageRangeVisible}
                          onCancel={handleAgeRangeCancel}
                          width={1000}
                          footer={null}
                        >
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.studies_by_age_range_graph) ? (
                              <Table dataSource={instituteDeepDiveData.studies_by_age_range_graph} columns={ageRangeColumns} />

                            ) : (
                              <span className="no-data-inside">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_age_range_graph) ? (
                        <Table dataSource={instituteDeepDiveData.studies_by_age_range_graph.slice(0, 4)} columns={ageRangeColumns} pagination={false} />

                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Phase vs Studies</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showPhaseModal}
                            role="presentation"
                          >
                            <Modal
                              title="Phases vs Studies"
                              visible={phaseVisible}
                              onCancel={handlePhaseCancel}
                              width={1000}
                              footer={null}
                            >
                              <div className="graph-view">
                                {showGraph(instituteDeepDiveData?.studies_by_phase_graph) ? (
                                  <Table dataSource={instituteDeepDiveData.studies_by_phase_graph} columns={phaseColumns} />

                                ) : (
                                  <span className="no-data-inside no-data-modal">No data available</span>
                                )}
                              </div>
                            </Modal>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_phase_graph) ? (
                        <>
                          <Table dataSource={instituteDeepDiveData.studies_by_phase_graph.slice(0, 4)} columns={phaseColumns} pagination={false} />

                        </>
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Studies per TA</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={showStudyPerTAModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Studies per TA"
                          open={studyPerTAVisible}
                          onCancel={handleStudyPerTACancel}
                          width={1000}
                          footer={null}
                        >
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.studies_by_TA_graph) ? (
                              <Table dataSource={instituteDeepDiveData.studies_by_TA_graph} columns={studiesPerTAColumns} />

                            ) : (
                              <span className="no-data-inside">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_TA_graph) ? (
                        <Table dataSource={instituteDeepDiveData.studies_by_TA_graph.slice(0, 4)} columns={studiesPerTAColumns} pagination={false} />

                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Active studies per study status</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={setActiveStudiesModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Active studies per study status"
                          open={activeStudies}
                          onCancel={handleActiveStudiesCancel}
                          width={1000}
                          footer={null}
                        >
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.studies_by_status_graph) ? (
                              <BarChart
                                data={instituteDeepDiveData?.studies_by_status_graph.slice(0, 50)}
                                width={(instituteDeepDiveData.studies_by_status_graph.length * 30) > 900 ? 1500 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="medianSIte"
                                checkDataset="centers"
                                titleName="MedianSite"
                                showModal
                                dateFilter={dateFilter}
                                allianceType={allianceType}
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            ) : (
                              <span className="no-data-inside">No data available</span>
                            )}
                            {showGraph(instituteDeepDiveData?.studies_by_status_graph.slice(0, 50)) ? (
                              <Table dataSource={instituteDeepDiveData?.studies_by_status_graph.slice(0, 50)} columns={activeStudyColumn} />

                            ) : (
                              <span className="no-data-inside">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.studies_by_status_graph.slice(0, 50)) ? (
                        <>
                          <Table dataSource={instituteDeepDiveData.studies_by_status_graph.slice(0, 4)} columns={activeStudyColumn} pagination={false} />

                        </>
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Median site startup period</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={setMedianSiteModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Median site startup period"
                          open={medianSite}
                          onCancel={handleMedianCancel}
                          width={1000}
                          footer={null}
                        >
                          <div className="graph-view barchart">
                            <div className="graph-view barchart">
                              {showGraph(instituteDeepDiveData?.median_site_startup_period.slice(0, 50)) ? (
                                <Table dataSource={instituteDeepDiveData?.median_site_startup_period.slice(0, 50)} columns={MedianColumns} />

                              ) : (
                                <span className="no-data-inside">No data available</span>
                              )}
                            </div>
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.median_site_startup_period.slice(0, 50)) ? (
                        <>
                          <Table dataSource={instituteDeepDiveData.median_site_startup_period.slice(0, 50)} columns={MedianColumns} pagination={false} />

                        </>
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Site SC Start-up Factor global and per TA</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={setSFGPerTAModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Site SC Start-up Factor global and per TA"
                          open={sFGPerTA}
                          onCancel={handleSFGPerTACancel}
                          width={1000}
                          footer={null}
                        >
                          <div className="graph-view barchart">
                            {showGraph(instituteDeepDiveData?.sc_startup_factor_per_ta) ? (
                              <BarChart
                                data={instituteDeepDiveData?.sc_startup_factor_per_ta.slice(0, 50)}
                                width={(instituteDeepDiveData.sc_startup_factor_per_ta.length * 30) > 900 ? 1500 : 900}
                                height={400}
                                top={15}
                                bottom={200}
                                left={30}
                                right={200}
                                studies="medianSIte"
                                checkDataset="centers"
                                titleName="MedianSite"
                                dateFilter={dateFilter}
                                allianceType={allianceType}
                                setIsConfirmationModal={setIsConfirmationModal}
                              />
                            ) : (
                              <span className="no-data-inside">No data available</span>
                            )}
                            {showGraph(instituteDeepDiveData?.sc_startup_factor_per_ta.slice(0, 50)) ? (
                              <Table dataSource={instituteDeepDiveData?.sc_startup_factor_per_ta.slice(0, 50)} columns={sfPerTaColumns} />

                            ) : (
                              <span className="no-data-inside">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.sc_startup_factor_per_ta.slice(0, 50)) ? (
                        <>
                          <Table dataSource={instituteDeepDiveData.sc_startup_factor_per_ta.slice(0, 50)} columns={sfPerTaColumns} pagination={false} />

                        </>
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}
                    </div>
                  </div>

                  <div className="graph-sec">
                    <div className="graph-header">
                      <div className="graph-title">Site SC Recruitment Factor global and per TA</div>
                      <div className="graph-meta-link">
                        <Tooltip title="Expand">
                          <div
                            className="goto-btn graph-head-icon"
                            onClick={setRFGPerTAModal}
                            role="presentation"
                          />
                        </Tooltip>
                        <Modal
                          title="Site SC Recruitment Factor global and per TA"
                          open={rFGPerTA}
                          onCancel={handleRFGPerTACancel}
                          width={1000}
                          footer={null}
                        >
                          <div className="graph-view barchart">

                            {showGraph(instituteDeepDiveData?.sc_recruitment_factor_per_ta.slice(0, 50)) ? (
                              <Table dataSource={instituteDeepDiveData?.sc_recruitment_factor_per_ta.slice(0, 50)} columns={rfPerTaColumns} />
                            ) : (
                              <span className="no-data-inside">No data available</span>
                            )}
                          </div>
                        </Modal>
                      </div>
                    </div>
                    <div className="graph-view barchart">
                      {showGraph(instituteDeepDiveData?.sc_recruitment_factor_per_ta.slice(0, 50)) ? (
                        <>
                          <Table dataSource={instituteDeepDiveData.sc_recruitment_factor_per_ta.slice(0, 50)} columns={rfPerTaColumns} pagination={false} />

                        </>
                      ) : (
                        <span className="no-data-inside">No data available</span>
                      )}

                    </div>
                  </div>

                </div>
              </div>
            </div>
          )}
        </div>
      </Loader>
      <Modal
        open={isConfirmationModal}
        onOk={() => setIsConfirmationModal(false)}
        onCancel={() => setIsConfirmationModal(false)}
        title="Download Confirmation"
        cancelButtonProps={{
          style: {
            display: 'none',
          },
        }}
      >
        Your file will be downloaded shortly.
      </Modal>
    </div>
  )
}
