import { GENERIC_ACTION } from '../SearchResults/logic'

const GET_BOOKMARK_DATA = 'GET_BOOKMARK_DATA'
const GET_BOOKMARK_DATA_MORE = 'GET_BOOKMARK_DATA_MORE'
const GET_BOOKMARK_DATA_MORE_SUCCESS = 'GET_BOOKMARK_DATA_MORE_SUCCESS'
const GET_BOOKMARK_DATA_SUCCESS = 'GET_BOOKMARK_DATA_SUCCESS'
const GET_BOOKMARK_DATA_FAILURE = 'GET_BOOKMARK_DATA_FAILURE'
const GET_BOOKMARK_DATA_RESET = 'GET_BOOKMARK_DATA_RESET'

const GET_COUNTS_DATA = 'GET_COUNTS_DATA'
const GET_COUNTS_DATA_SUCCESS = 'GET_COUNTS_DATA_SUCCESS'
const GET_COUNTS_DATA_FAILURE = 'GET_COUNTS_DATA_FAILURE'

const INITIAL_STATE = {
  data: {},
  loading: false,
  error: false,
  flag: false,
}

export const getBookmarkCountsDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_COUNTS_DATA,
    url: `${process.env.apiUrl}/v0/bookmark/count`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export function getBookmarkCountsDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_COUNTS_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_COUNTS_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }
    case GET_COUNTS_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}

export const getBookmarkDataAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_BOOKMARK_DATA,
    url: `${process.env.apiUrl}/v0/bookmark/getBookmark`,
    method: 'get',
    auth: true,
    log: false,
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getBookmarkDataMoreAction = (payload, meta = {}) => ({
  type: GENERIC_ACTION,
  payload,
  meta: {
    actionType: GET_BOOKMARK_DATA_MORE,
    url: `${process.env.apiUrl}/v0/bookmark/getBookmark`,
    method: 'get',
    auth: true,
    // customHeaders: { 'Content-Type': undefined },
    log: false, // for console
    ...meta,
  },
  showNotification: false, // show notification on success
  hideNotification: false, // hide notification on error
})

export const getBookmarkDataResetAction = () => ({
  type: GET_BOOKMARK_DATA_RESET,
})

export function getBookmarkDataReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_BOOKMARK_DATA: {
      return {
        data: {},
        loading: true,
        flag: false,
        error: false,
      }
    }
    case GET_BOOKMARK_DATA_SUCCESS: {
      return {
        data: action.payload,
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_BOOKMARK_DATA_MORE_SUCCESS: {
      return {
        data: {
          data: [
            ...state.data.data,
            ...action.payload.data,
          ],
          total: action.payload.total,
        },
        loading: false,
        flag: true,
        error: false,
      }
    }

    case GET_BOOKMARK_DATA_RESET: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: false,
      }
    }

    case GET_BOOKMARK_DATA_FAILURE: {
      return {
        data: {},
        loading: false,
        flag: false,
        error: true,
      }
    }
    default:
      return state
  }
}
